// react imports
import React, {
	useState,
	createContext,
	useEffect,
	useCallback,
	lazy,
	Suspense,
} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer, Flip } from "react-toastify";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
	deleteUserSession,
	markNotificationAsRead,
	pingServer,
	getGames,
	returnUserByToken,
	getUser,
} from "./api";

// constants
import {
	LOGIN_SESSION_NAME,
	TOAST_MESSAGE,
	TOAST_ERROR,
	ADMIN_MAIN_ROLE,
	PARTNER_MAIN_ROLE,
	MODERATOR_MAIN_ROLE,
	MITTEN_MAIN_ROLE,
	DONATOR_MAIN_ROLE,
	DONATOR_PLUS_MAIN_ROLE,
	BANNED_USER,
	DEACTIVATED_ACCOUNT,
} from "./constants";

// site components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/HomePage/HomePage";
import Announcement from "./components/Announcements/Announcements";
import Levels from "./components/Levels/Levels";
import Level from "./components/Levels/Level/Level";
import { Games } from "./components/Games/Games";
import GameTest from "./components/Games/GameTest/GameTest";
import SMCGame from "./components/Games/SMC/SMCPage";
import YFSGame from "./components/Games/YFS/YFSPage";
//* import SMFGame from "./components/Games/SMF/SMFPage";
import Auth from "./components/Auth/Auth";
import Members from "./components/Users/Members/Members";
import Profile from "./components/Users/Profile/Profile";
import Contact from "./components/Contact/Contact";
import ToS from "./components/ToS/ToS";
import FAQ from "./components/FAQ/FAQ";
import Guidelines from "./components/Guidelines/Guidelines";
import About from "./components/About/About";
import Verify from "./components/Verify/Verify";
import ForgotPass from "./components/Auth/ForgotPass/ForgotPass";
import ResetPass from "./components/Auth/ResetPass/ResetPass";
import PageNotFound from "./components/NotFound/PageNotFound";
import ThemeRenderer from "./themes/ThemeRenderer";

// import bootstrap and global styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

// import toast library
import "react-toastify/dist/ReactToastify.css";

// site logos and images
import LSSLogo from "./assets/images/LSSLogo.png";
import LSSLogoTransparent from "./assets/images/LSSLogoTransparent.png";
import Exclamation from "./assets/images/Exclamation.png";
import defaultAvatar from "./assets/images/DefaultAvatar.webp";
import { sleep } from "./functions/utils";

export const UserContext = createContext<any>(null);

// lazy loading
const InfractionModal = lazy(() => import("./components/Modals/InfractionLog"));
const RankManager = lazy(() => import("./components/RankManager/RankManager"));
const AddEditLevel = lazy(
	() => import("./components/Levels/AddEditLevel/AddEditLevel")
);
const ReportForm = lazy(() => import("./components/Report/Report"));
const Account = lazy(() => import("./components/Users/Account/Account"));
const Notifications = lazy(
	() => import("./components/Notifications/Notifications")
);
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const Customization = lazy(
	() => import("./components/Users/Customization/Customization")
);
const Staff = lazy(() => import("./components/Staff/Staff"));

/**
 * The main entry point to the site's HTML.
 *
 * @returns The correct page to display given the browser route.
 */
const App = () => {
	const [musicActive, setMusicActive] = useState<boolean>(false);
	const [videoId, setVideoId] = useState<string>("ju1NDfacnQ8"); // Replace with the actual YouTube video ID

	const [localUserToken, setLocalUserToken] = useState<any>(null);
	const [loggedInUserId, setLoggedInUserId] = useState(null);
	const [currentUserAvatar, setCurrentUserAvatar] = useState(null);

	const [gameTabs, setGameTabs] = useState({});

	// the currently logged in user, if one exists, and a method to modify it.
	const [currentUserData, setCurrentUserData] = useState<any>(null);

	//! reference for page navigation to re-call useEffect hooks
	const [navChanged, setNavChanged] = useState(true);
	const [currentLocation, setCurrentLocation] = useState(
		window.location.pathname
	);

	// game names, acronyms, and urls ordered by id
	const [gameProperties, setGameProperties] = useState<any[]>([]);

	const [serverIsOnline, setServerIsOnline] = useState(true);
	const [pingStatus, setPingStatus] = useState<null | string>(null);
	const [refocus, setRefocus] = useState(false);
	// new notification toasting
	const [newNotifications, setNewNotifications] = useState<any[]>([]);
	const [hasNewNotifications, setHasNewNotifications] = useState(false);
	const [hasNewRewards, setHasNewRewards] = useState(false);
	const [notificationBellStatus, setNotificationBellStatus] =
		useState("none");
	// state used on notification page
	const [hasDoneQuery, setHasDoneQuery] = useState(false);
	// state for opening infraction modal
	const [infractionModalShow, setInfractionModalShow] = useState(false);
	const [xpModalShow, setXpModalShow] = useState(false);

	//* useEffect hook & function to perform a backend status call every 2 minutes
	const intervalLogic = useCallback(
		(mode) => {
			if (currentUserData?._id && serverIsOnline) {
				// don't ping the server when already requesting
				if (pingStatus !== null) return;
				setPingStatus(mode); // update ping status
				const path = window.location.pathname.toLowerCase();
				// browser games
				const browserGame = gameProperties?.find(
					(game) =>
						!game?.url?.includes("https://") &&
						path?.includes(game?.url)
				);
				if (browserGame) mode = browserGame?.acronym;
				// check if document is hidden
				const hidden = document.hidden;
				// ping the server
				pingServer(mode, hidden)
					.then((response) => {
						const data = response?.data;
						setNavChanged(false);
						setPingStatus(null);
						// if there are new rewards
						if (data?.unclaimedRewards)
							setHasNewRewards(data?.unclaimedRewards);
						// empty response
						if (response?.status === 204)
							return setNotificationBellStatus("none");

						// response containing notification data
						if (
							response?.status === 208 &&
							window.location.pathname !== "/notifications"
						)
							// header bell styling related styles
							setNotificationBellStatus("unread");
						if (data?.notifications || data?.onClick) {
							// update the notification page, general use for any new notifications
							setHasDoneQuery(false);
							if (window.location.pathname !== "/notifications")
								setNotificationBellStatus("new");
						}
						if (data?.notifications) {
							// update states if there are notifications in the response, which toasts all of them
							setNewNotifications(data.notifications);
							setHasNewNotifications(true);
						}
					})
					.catch(() => {
						setNavChanged(false);
						setPingStatus(null);
					});
			}
		},
		[currentUserData?._id, serverIsOnline, pingStatus, gameProperties]
	);

	// get games from server once on initial site load
	useEffect(() => {
		getGames().then((response) => {
			setGameProperties(response?.data?.games);
		});
	}, []);

	// check for notifications every 2 minutes, also upon refresh
	useEffect(() => {
		// set an interval of 2 minutes
		const interval = setInterval(() => {
			if (!currentUserData?._id) return clearInterval(interval);
			intervalLogic("ping");
			setRefocus(false);
		}, 75 * 1000);

		// cleanup function
		return () => clearInterval(interval);
	});

	useEffect(() => {
		// handle the focussing of the tab
		const handleFocus = () => setRefocus(true);
		// ping
		if (refocus) {
			setRefocus(false);
			intervalLogic("ping");
		}
		// event listener
		window.addEventListener("focus", handleFocus);
		// cleanup
		return () => {
			window.removeEventListener("focus", handleFocus);
		};
	}, [refocus, intervalLogic]);

	useEffect(() => {
		// variables for url and "www" subdomain regex
		const currentURL = window.location.href;
		const wwwRegex = /^(https?:\/\/)?(www\.)?(.*)/;
		const wwwRegexArr = wwwRegex.exec(currentURL);

		// check if the "www" subdomain is present in the url
		if (
			wwwRegexArr &&
			wwwRegexArr?.length >= 4 &&
			wwwRegexArr[2] === "www."
		) {
			// remove the "www" subdomain from the url
			const updatedURL = `${wwwRegexArr[1] ?? ""}${wwwRegexArr[3]}`;

			// redirect to the updated url
			window.location.href = updatedURL;
		}

		// upon changing page, fire reset logic
		if (currentUserData?._id && navChanged) {
			return intervalLogic("reset");
		}

		// rerun once a value changes
	}, [currentUserData?._id, intervalLogic, navChanged]);

	// handle notification status for marking as read
	const handleMarkNotificationAsRead = (input, setLockAction?) => {
		return new Promise((resolve) => {
			if (input === "all") {
				// if everything is being marked as read
				markNotificationAsRead("all")
					.then(() => {
						setHasNewNotifications(false);
						setNotificationBellStatus("none");
						if (setLockAction) setLockAction(false);
						resolve("Success"); // Don't return here
					})
					.catch(() => {
						if (setLockAction) setLockAction(false);
						resolve("Failed"); // Resolve with an appropriate message
					});
			} else {
				// singular notification
				markNotificationAsRead(input)
					.then((response) => {
						if (response?.data?.notificationCount === 0) {
							setHasNewNotifications(false);
							setNotificationBellStatus("none");
						}
						if (setLockAction) setLockAction(false);
						resolve("Success"); // Don't return here
					})
					.catch(() => {
						if (setLockAction) setLockAction(false);
						resolve("Failed"); // Resolve with an appropriate message
					});
			}
		});
	};

	// useEffect hook trigger upon page rerender
	useEffect(() => {
		setLocalUserToken(localStorage.getItem(LOGIN_SESSION_NAME));

		if (localStorage.getItem(LOGIN_SESSION_NAME) === null) {
			setLocalUserToken("notLoggedIn");
		}

		if (!loggedInUserId) {
			returnUserByToken()
				.then((response) => {
					if (response?.data?.user)
						setLoggedInUserId(response.data.user);
				})
				.catch((error) => {
					// otherwise render page
					setCurrentUserData(null);
					setLocalUserToken("notLoggedIn");
					// if the server is unreachable
					if (
						[502, 504].includes(error.response.status) &&
						serverIsOnline
					) {
						setServerIsOnline(false);
						return toast.error(
							"Unable to connect to LSS servers, are they down for maintenance or due to another reason?"
						);
					}
					// if the session token is expired, logout
					if (error.response.status === 440) {
						deleteUserSession().then(() => {
							setLoggedInUserId(null);
							localStorage.removeItem(LOGIN_SESSION_NAME);
							toast.warn(
								"Your session has expired, logging out..."
							);
							setTimeout(() => logout(), 1000);
						});
					}
				});
		}

		// Get the latest user data from the database. This data is also available to components using the UserContext component.
		if (loggedInUserId !== null && !currentUserData) {
			getUser(loggedInUserId)
				.then((response) =>
					performCurrentUserDataChecks(response.data.user)
				)
				.catch((error) => console.error(error));
		}

		const performCurrentUserDataChecks = (userData) => {
			// Remove password from locally stored userData object for security.
			// It should never be displayed or checked on by the frontend anyways!
			delete userData.password;

			// Shorthand for checking if a site user is staff (eliminates the need to loop through the roles twice)
			// For different staff members (Moderators, Admins) the role can still be checked for.
			// MITTEN = MOD IN TRAINING
			const mainRoles = userData?.mainRoles;
			if (
				mainRoles.includes(MITTEN_MAIN_ROLE) ||
				mainRoles.includes(MODERATOR_MAIN_ROLE) ||
				mainRoles.includes(PARTNER_MAIN_ROLE) ||
				mainRoles.includes(ADMIN_MAIN_ROLE)
			) {
				userData.isStaff = true;
				// define isMod
				if (
					mainRoles.includes(MODERATOR_MAIN_ROLE) ||
					mainRoles.includes(PARTNER_MAIN_ROLE) ||
					mainRoles.includes(ADMIN_MAIN_ROLE)
				)
					userData.isMod = true;
				// define isAdmin
				if (mainRoles.includes(ADMIN_MAIN_ROLE))
					userData.isAdmin = true;
			}

			// check for donator
			if (
				mainRoles.includes(DONATOR_MAIN_ROLE) ||
				mainRoles.includes(DONATOR_PLUS_MAIN_ROLE)
			)
				userData.isDonator = true;
			if (mainRoles.includes(DONATOR_PLUS_MAIN_ROLE))
				userData.isDonatorPlus = true;

			// Finally, pass the modified userData object, and it becomes the new user data passed to other components.
			setCurrentUserData(userData);
			setCurrentUserAvatar(userData.avatar);
		};
		// eslint-disable-next-line
	}, [loggedInUserId, currentUserData?.isStaff, currentUserData?.isAdmin]);

	const logout = useCallback(() => {
		// remove necessary entries in local storage
		localStorage.removeItem(LOGIN_SESSION_NAME);
		sessionStorage.clear();

		if (
			(currentUserData?.mainRoles.includes(BANNED_USER) ||
				currentUserData?.mainRoles.includes(DEACTIVATED_ACCOUNT)) &&
			!currentUserData?.mainRoles.includes(ADMIN_MAIN_ROLE)
		) {
			currentUserData?.mainRoles.includes(BANNED_USER)
				? localStorage.setItem(
						TOAST_ERROR,
						"Your journey ends here. (Banned)"
				  )
				: localStorage.setItem(
						TOAST_ERROR,
						"Your account was deactivated, contact us if you think this is an error."
				  );
		} else {
			localStorage.setItem(
				TOAST_MESSAGE,
				"Your return shall be pondered."
			);
		}
		// update user session and scroll
		window.scrollTo(0, 0);
		setCurrentUserData(null);
		setLoggedInUserId(null);
		sleep(2500);
		window.location.reload();
	}, [currentUserData?.mainRoles]);

	useEffect(() => {
		const toastMessage = localStorage?.getItem(TOAST_MESSAGE);
		const toastError = localStorage?.getItem(TOAST_ERROR);

		if (toastMessage) {
			localStorage.removeItem(TOAST_MESSAGE);
			toast.info(toastMessage);
		}
		if (toastError) {
			localStorage.removeItem(TOAST_ERROR);
			toast.info(toastError);
		}
	}, []);

	// The max thumbnail size of level images (in bytes), which is 5MB.
	const maxThumbnailSize = 5000000;

	const verifiedUser = currentUserData?.verified === true;

	if (currentUserData || localUserToken === "notLoggedIn") {
		// Render everything when user data was attempted to fetch (even if it doesn't exist)
		return (
			<UserContext.Provider value={currentUserData}>
				{/* toast message telling user what they need to know */}
				<ToastContainer
					position="bottom-left"
					hideProgressBar
					transition={Flip}
				/>
				<BrowserRouter>
					{/* navbar */}
					<div className="site-body">
						<Header
							setNavChanged={setNavChanged}
							navChanged={navChanged}
							setCurrentLocation={setCurrentLocation}
							currentLocation={currentLocation}
							currentUserAvatar={currentUserAvatar}
							notificationBellStatus={notificationBellStatus}
							setInfractionModalShow={setInfractionModalShow}
							images={{ logo: LSSLogo, defaultAvatar }}
							hasNewNotifications={hasNewNotifications}
							setHasNewNotifications={setHasNewNotifications}
							newNotifications={newNotifications}
							handleMarkNotificationAsRead={
								handleMarkNotificationAsRead
							}
							setXpModalShow={setXpModalShow}
							hasNewRewards={hasNewRewards}
							logout={logout}
							gameProperties={gameProperties}
						/>
						<main>
							<ThemeRenderer
								setMusicActive={setMusicActive}
								musicActive={musicActive}
								videoId={videoId}
								setVideoId={setVideoId}
							/>
							{currentUserData?._id ? (
								<Suspense
									fallback={
										<div className="d-flex justify-content-center">
											<Spinner
												animation="grow"
												variant="primary"
											/>
										</div>
									}>
									<RankManager
										xpModalShow={xpModalShow}
										setXpModalShow={setXpModalShow}
										setCurrentUserData={setCurrentUserData}
									/>
									<InfractionModal
										infractionModalShow={
											infractionModalShow
										}
										setInfractionModalShow={
											setInfractionModalShow
										}
									/>
								</Suspense>
							) : null}

							{/* show the right content depending on the page */}
							<Switch>
								{/* home page */}
								<Route
									exact
									path="/"
									render={(props) => (
										<HomePage
											{...props}
											images={{
												Exclamation: Exclamation,
												defaultAvatar: defaultAvatar,
											}}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* individual announcement page */}
								<Route
									exact
									path="/announcements/:id"
									render={(props) => (
										<Announcement
											{...props}
											images={{
												defaultAvatar: defaultAvatar,
											}}
										/>
									)}
								/>
								{/* general level page */}
								<Route
									exact
									path="/levels"
									render={(props) => (
										<Levels
											{...props}
											setCurrentUserData={
												setCurrentUserData
											}
											gameProperties={gameProperties}
										/>
									)}
								/>
								<Route
									exact
									path="/:game/levels"
									render={(props) => (
										<Levels
											{...props}
											setCurrentUserData={
												setCurrentUserData
											}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* user level pages */}
								<Route
									path="/levels/user/:id"
									render={(props) => (
										<Levels
											{...props}
											setCurrentUserData={
												setCurrentUserData
											}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* add level page */}
								<Route
									path="/levels/add"
									render={() => {
										return (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<AddEditLevel
													maxThumbnailSize={
														maxThumbnailSize
													}
													images={{
														Exclamation:
															Exclamation,
													}}
													gameProperties={
														gameProperties
													}
												/>
											</Suspense>
										);
									}}
								/>
								{/* edit level page */}
								<Route
									path="/levels/edit/:id"
									render={(props) => {
										return (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<AddEditLevel
													{...props}
													maxThumbnailSize={
														maxThumbnailSize
													}
													images={{
														Exclamation:
															Exclamation,
													}}
													gameProperties={
														gameProperties
													}
												/>
											</Suspense>
										);
									}}
								/>
								{/* individual level pages */}
								<Route
									path="/levels/:id"
									render={(props) => (
										<Level
											{...props}
											setCurrentUserData={
												setCurrentUserData
											}
											gameTabs={gameTabs}
											setGameTabs={setGameTabs}
											images={{
												LSSLogo: LSSLogo,
												defaultAvatar: defaultAvatar,
												Exclamation: Exclamation,
											}}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* Games list page */}
								<Route
									exact
									path="/games"
									render={(props) => (
										<Games
											{...props}
											loggedInUser={currentUserData}
											setCurrentUserData={
												setCurrentUserData
											}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* SMC page */}
								<Route
									exact
									path="/games/supermarioconstruct"
									render={(props) => (
										<SMCGame
											{...props}
											loggedInUser={currentUserData}
											setCurrentUserData={
												setCurrentUserData
											}
										/>
									)}
								/>
								{/* YFS page */}
								<Route
									exact
									path="/games/yoshisfabricationstation"
									render={(props) => (
										<YFSGame
											{...props}
											loggedInUser={currentUserData}
											setCurrentUserData={
												setCurrentUserData
											}
										/>
									)}
								/>
								{/* game test page */}
								<Route
									exact
									path="/games/gametest"
									render={(props) =>
										currentUserData?.isStaff ? (
											<GameTest
												{...props}
												loggedInUser={currentUserData}
												setCurrentUserData={
													setCurrentUserData
												}
											/>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/** SMF page 
						<Route
							exact
							path="/games/supermarioflash"
							render={(props) => (
								<SMFGame
									{...props}
									loggedInUser={currentUserData}
									setCurrentUserData={setCurrentUserData}
								/>
							)}
						/> */}
								{/* login/register page */}
								<Route
									exact
									path="/auth"
									render={() =>
										// only render if user is not logged in
										!currentUserData ? (
											<Auth
												loggedInUser={currentUserData}
												setCurrentUserData={
													setCurrentUserData
												}
												images={{
													LSSLogoTransparent:
														LSSLogoTransparent,
													Exclamation: Exclamation,
												}}
											/>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* dashboard page */}
								<Route
									path="/dashboard"
									render={(props) =>
										currentUserData?._id ? (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<Dashboard
													{...props}
													setHasNewRewards={
														setHasNewRewards
													}
													gameProperties={
														gameProperties
													}
												/>
											</Suspense>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* notifications page */}
								<Route
									path="/notifications"
									render={(props) =>
										currentUserData?._id ? (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<Notifications
													{...props}
													hasDoneQuery={hasDoneQuery}
													setHasDoneQuery={
														setHasDoneQuery
													}
													setInfractionModalShow={
														setInfractionModalShow
													}
													handleMarkNotificationAsRead={
														handleMarkNotificationAsRead
													}
													setXpModalShow={
														setXpModalShow
													}
												/>
											</Suspense>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* members page */}
								<Route
									path="/members"
									render={(props) => (
										<Members
											{...props}
											loggedInUser={currentUserData}
											setCurrentUserData={
												setCurrentUserData
											}
										/>
									)}
								/>
								{/* events page */}
								{/*<Route path="/events" render={() => <Events />} />*/}
								{/* profile page */}
								<Route
									path="/users/:id"
									render={(props) => (
										<Profile
											{...props}
											setCurrentUserData={
												setCurrentUserData
											}
											loggedInUser={currentUserData}
											setInfractionModalShow={
												setInfractionModalShow
											}
											images={{
												defaultAvatar: defaultAvatar,
											}}
											videoId={videoId}
											setVideoId={setVideoId}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* account customization page */}
								<Route
									path="/customization/:id"
									render={(props) =>
										currentUserData &&
										props.match?.params?.id?.length ===
											24 &&
										(currentUserData._id ===
											props.match.params.id ||
											currentUserData.isStaff) ? (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<Customization
													{...props}
													images={{
														Exclamation,
														defaultAvatar,
													}}
													currentUserAvatar={
														currentUserAvatar
													}
													setCurrentUserAvatar={
														setCurrentUserAvatar
													}
													setCurrentUserData={
														setCurrentUserData
													}
													loggedInUser={
														currentUserData
													}
												/>
											</Suspense>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* account settings page */}
								<Route
									path="/account"
									render={(props) =>
										// only render if used is logged in
										currentUserData ? (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<Account
													{...props}
													images={{
														Exclamation,
													}}
													setCurrentUserData={
														setCurrentUserData
													}
													gameProperties={
														gameProperties
													}
												/>
											</Suspense>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* report page */}
								<Route
									exact
									path="/report"
									render={(props) =>
										currentUserData &&
										(verifiedUser ||
											window?.location?.search?.includes(
												"?type=feedback"
											)) ? (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<ReportForm
													{...props}
													loggedInUser={
														currentUserData
													}
													setCurrentUserData={
														setCurrentUserData
													}
													images={{
														Exclamation:
															Exclamation,
													}}
												/>
											</Suspense>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* Staff dashboard */}
								<Route
									path="/staff"
									render={() =>
										// only render if used is logged in
										currentUserData?.isStaff ? (
											<Suspense
												fallback={
													<div className="d-flex justify-content-center">
														<Spinner
															animation="grow"
															variant="primary"
														/>
													</div>
												}>
												<Staff
													setCurrentUserData={
														setCurrentUserData
													}
												/>
											</Suspense>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* terms of service */}
								<Route
									path="/tos"
									render={() => (
										<ToS
											images={{
												Exclamation: Exclamation,
											}}
										/>
									)}
								/>
								{/* faq */}
								<Route
									path="/faq"
									render={() => (
										<FAQ
											images={{
												Exclamation: Exclamation,
											}}
										/>
									)}
								/>
								{/* rules & guidelines */}
								<Route
									path="/guidelines"
									render={() => (
										<Guidelines
											images={{
												Exclamation: Exclamation,
											}}
										/>
									)}
								/>
								{/* contact page */}
								<Route
									path="/contact"
									render={() => (
										<Contact
											images={{
												Exclamation: Exclamation,
											}}
										/>
									)}
								/>
								{/* about page */}
								<Route
									path="/about"
									render={() => (
										<About
											images={{
												Exclamation: Exclamation,
											}}
											gameProperties={gameProperties}
										/>
									)}
								/>
								{/* verification notice page */}
								<Route
									exact
									path="/verify"
									render={() => (
										<Verify
											images={{
												Exclamation: Exclamation,
											}}
										/>
									)}
								/>
								{/* forgot password page */}
								<Route
									exact
									path="/forgotpass"
									render={() =>
										!currentUserData ? (
											<ForgotPass
												images={{
													Exclamation: Exclamation,
												}}
											/>
										) : (
											<Redirect to="/" />
										)
									}
								/>
								{/* reset password page */}
								<Route
									path="/resetpass"
									render={() => <ResetPass />}
								/>
								{/* 404 (page not found) page */}
								<Route
									component={PageNotFound}
									path="/pagenotfound"
								/>
								<Redirect to="/pagenotfound" />
							</Switch>

							{/* footer */}
						</main>
						<Footer />
					</div>
				</BrowserRouter>
			</UserContext.Provider>
		);
	} else {
		// Render just the header and spinner otherwise
		return (
			<>
				<BrowserRouter>
					{/* navbar */}
					<div className="site-body">
						<Header
							setNavChanged={setNavChanged}
							navChanged={navChanged}
							setCurrentLocation={setCurrentLocation}
							currentLocation={currentLocation}
							images={{
								logo: LSSLogo,
								defaultAvatar: defaultAvatar,
							}}
							gameProperties={gameProperties}
						/>
						<main>
							<ThemeRenderer
								setMusicActive={setMusicActive}
								musicActive={musicActive}
								videoId={videoId}
								setVideoId={setVideoId}
							/>
							<div className="d-flex justify-content-center">
								<Spinner animation="grow" variant="primary" />
							</div>
						</main>
						<Footer />
					</div>
				</BrowserRouter>
			</>
		);
	}
};

export default App;
