// react imports
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Import UserContext that contains the latest user information from the database
import { UserContext } from "../../App";

// components
import {
	getSharedLevelsPage,
	updateUserLevels,
	getFeaturedLevelsPage,
	getLevelsList,
} from "../../api";
import Pagination from "../Pagination/Pagination";

// page styles
import "./style.scss";

// react-bootstrap components used
import { OverlayTrigger, Tooltip, Spinner, Nav } from "react-bootstrap";
import { Link } from "@mui/material";
import ImageStarContainer from "./ImageStarContainer/ImageStarContainer";
import { Game } from "../Games/Games";

// constants
import { DELETED_LEVEL, FEATURED_LEVEL, PRIVATE_LEVEL } from "../../constants";

// Get all url parameters (page for paging, and levelQuery/author/tags for search)
// The query const is made below that you can use to get current params, e.g. query.get('page')
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

/**
 * Display a list of levels.
 *
 * @returns
 */
const Levels = (props) => {
	// Obtain the current user data (from React UserContext), passed from the App component
	const currentUserData = useContext<any>(UserContext);
	const allGames = props?.gameProperties;
	const gameCount = allGames?.length - 1;
	const validGameParamID =
		props?.match?.params?.game >= -1 &&
		props?.match?.params?.game <= gameCount;
	const validGameParamAcronym = allGames
		?.map((game) => game?.acronym)
		?.includes(props?.match?.params?.game);
	const [gameParam, setGameParam] = useState<number>(
		validGameParamID || validGameParamAcronym // condition
			? // if true
			  props?.match?.params?.game
			: // false
			props?.match?.params?.game
			? -1
			: NaN
	);
	const [gameParamChanged, setGameParamChanged] = useState(false);

	// user id shorthand
	const [userID, setUserID] = useState(props.match?.params?.id);

	// state info
	const [levels, setLevels] = useState<any[]>([]);
	const [sharedLevels, setSharedLevels] = useState<any[]>([]);
	const [featuredLevels, setFeaturedLevels] = useState<any[]>([]);

	const [levelListState, setLevelListState] = useState("normal");
	const [levelsToMap, setLevelsToMap] = useState<any[]>([]);

	// create an initial state
	const initialState: any = useMemo(() => {
		return {
			normal: null,
			shared: null,
			featured: null,
		};
	}, []);
	const [levelMessage, setLevelMessage] = useState<any>(null);
	const [pageNumberStore, setPageNumberStore] = useState<any>(initialState);
	const [previousPage, setPreviousPage] = useState<any>(-1);

	const [isQuerying, setIsQuerying] = useState(false);
	const [levelsChanged, setLevelsChanged] = useState(true);
	const [numberOfPages, setNumberOfPages] = useState(0);

	const [rolledRandomLevels, setRolledRandomLevels] = useState(false);
	const [randomState, setRandomState] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [showSearchButton, setShowSearchButton] = useState(true);
	const [changedGameDropdown, setChangedGameDropdown] = useState(false);
	const [userList, setUserList] = useState<boolean>(
		window.location.pathname.includes("/levels/user/")
	);

	const validLevelListState =
		validGameParamID ||
		validGameParamAcronym ||
		gameParam === -1 ||
		userList;

	// hook initializations
	const history = useHistory();
	const query = useQuery();

	// url parameters
	const page =
		parseInt(query.get("page") ?? "0") > 0
			? parseInt(query.get("page") ?? "0")
			: 1;
	const levelQueryParam = query.get("levelQuery");

	//setting which level to map
	useEffect(() => {
		if (levelListState === "normal" && levels) {
			setLevelsToMap(levels);

			if (pageNumberStore?.normal !== 0)
				setNumberOfPages(pageNumberStore.normal);
		}
		if (levelListState === "shared" && levels) {
			setLevelsToMap(sharedLevels);
			if (pageNumberStore?.shared !== 0)
				setNumberOfPages(pageNumberStore.shared);
		}
		if (levelListState === "featured" && levels) {
			setLevelsToMap(featuredLevels);
			if (pageNumberStore?.featured !== 0)
				setNumberOfPages(pageNumberStore.featured);
		}
	}, [levelListState, levels, sharedLevels, featuredLevels, pageNumberStore]);

	// page load and unload events
	useEffect(() => {
		document.title = "Levels - Level Share Square";

		// cleanup
		return () => {
			setLevelsChanged(false);
			setLevels([null]);
		};
	}, []);

	useEffect(() => {
		return history.listen((location) => {
			if (location?.pathname === "/levels")
				return setGameParamChanged(true);
		});
	}, [history]);

	// page change event (history push to a different level list)
	useEffect(() => {
		if (props?.match?.params?.id !== userID || gameParamChanged) {
			setLevels([]);
			setSharedLevels([]);
			setFeaturedLevels([]);
			setLevelsToMap([]);
			setLevelListState("normal");
			setLevelMessage(null);
			setPageNumberStore(initialState);
			setUserID(props.match.params.id);
			setPreviousPage(-1);
			setLevelsChanged(true);
			setGameParam(props?.match?.params?.game);
			setGameParamChanged(false);
			setUserList(window.location.pathname.includes("/levels/user/"));
		}

		return () => {
			setLevelsChanged(true);
			setIsQuerying(false);
			setLevels([]);
			setSharedLevels([]);
			setFeaturedLevels([]);
			setLevelsToMap([]);
		};
	}, [
		props.match.params.id,
		userID,
		initialState,
		gameParamChanged,
		props.match.params?.game,
	]);

	// keep url parameter and state info in sync for searches
	useEffect(() => {
		if (levelQueryParam && searchQuery !== levelQueryParam) {
			setSearchQuery(levelQueryParam);
			setShowSearchButton(false);
		}
		// eslint-disable-next-line
	}, [levelQueryParam]);

	// retrieve all level info
	useEffect(() => {
		if (
			previousPage === page &&
			!changedGameDropdown &&
			!rolledRandomLevels
		) {
			return setLevelsChanged(false);
		}
		// fetch the current page of levels
		if (
			isQuerying === false &&
			levelListState === "normal" &&
			levelsChanged === true &&
			validLevelListState
		) {
			setChangedGameDropdown(false);
			setIsQuerying(true);
			setLevelsChanged(false);
			setLevelsToMap([]);
			setLevelMessage(null);
			getLevelsList({
				page,
				name: searchQuery ?? "",
				author: userID ? userID : "",
				authors: true,
				game: gameParam,
				safemode: userID ? "0" : "2",
				appendFeatured: true,
				random: rolledRandomLevels ? true : false,
			})
				.then((response) => {
					setLevels(response?.data?.levels);
					if (rolledRandomLevels) {
						setRandomState(true);
						toast.success("Rolled a selection of random levels!");
						setRolledRandomLevels(false);
						return setNumberOfPages(1);
					}
					setPreviousPage(page);
					setPageNumberStore((prevState) => {
						// update amount of regular pages
						return {
							...prevState,
							normal: response?.data?.numberOfPages,
						};
					});
					setNumberOfPages(response?.data?.numberOfPages);
					setLevelMessage(response?.data?.message);
				})
				.catch((err) => {
					setLevels([]);
					setNumberOfPages(0);
					setLevelMessage(
						userID
							? "Couldn't find any levels for this user."
							: err?.response?.data?.message || "No levels found."
					);
				})
				.finally(() => {
					setIsQuerying(false);
					setRolledRandomLevels(false);
				});
		}
	}, [
		levelsChanged,
		currentUserData?._id,
		userID,
		page,
		levelMessage,
		searchQuery,
		isQuerying,
		gameParam,
		previousPage,
		levelListState,
		initialState,
		changedGameDropdown,
		props.gameProperties,
		rolledRandomLevels,
		validLevelListState,
	]);

	// handle changing the game dropdown
	useEffect(() => {
		if (changedGameDropdown) {
			setLevelMessage(null);
			setPageNumberStore(initialState);
			setPreviousPage(-1);
			setLevels([]);
			setFeaturedLevels([]);
			setSharedLevels([]);
			setLevelsChanged(true);
			history.push(
				props.location.pathname +
					(levelQueryParam ? `?levelQuery=${searchQuery}` : "")
			);
		}
	}, [
		changedGameDropdown,
		props.location.pathname,
		levelQueryParam,
		searchQuery,
		history,
		initialState,
	]);

	const handleKeyPress = (e) => {
		/* if the user presses the enter key (key code 13),
		 * search the same as if the search button were pressed */
		if (e.keyCode === 13) searchOrClearLevels(e);
	};

	// switch between level list and display for levels that have shared access
	const handleSharedAccessFetch = () => {
		setPreviousPage(-1);
		setLevelsChanged(true);
		query.set("page", "1");
		history.push(window.location.pathname + "?" + query.toString());

		if (levelListState !== "shared") return setLevelListState("shared");

		if (levelListState === "shared") return setLevelListState("normal");
	};

	//! accompanied useEffect hook
	useEffect(() => {
		if (previousPage === page && !changedGameDropdown) {
			return setLevelsChanged(false);
		}

		if (
			levelListState === "shared" &&
			levelsChanged &&
			!isQuerying &&
			validLevelListState
		) {
			setIsQuerying(true);
			setChangedGameDropdown(false);
			setLevelsToMap([]);
			getSharedLevelsPage({
				page,
				searchQuery: searchQuery ?? "",
				game: gameParam,
			}).then((response) => {
				if (response) {
					setPageNumberStore((prevState) => {
						// update amount of shared pages
						return {
							...prevState,
							shared: response.data.numberOfPages,
						};
					});
					setNumberOfPages(response.data.numberOfPages);
					setPreviousPage(page);
					setSharedLevels(response.data.levels);
					setLevelMessage(response.data.message);

					setIsQuerying(false);
				}
			});
		}
	}, [
		validLevelListState,
		levelListState,
		isQuerying,
		levelsChanged,
		page,
		searchQuery,
		gameParam,
		previousPage,
		changedGameDropdown,
	]);

	// switch level list to display featured levels
	const handleFeaturedFetch = () => {
		setPreviousPage(null);
		setLevelsChanged(true);
		query.set("page", "1");
		history.push(window.location.pathname + "?" + query.toString());

		if (levelListState !== "featured") return setLevelListState("featured");

		if (levelListState === "featured") return setLevelListState("normal");
	};

	//! accompanied useEffect hook
	useEffect(() => {
		if (previousPage === page && !changedGameDropdown)
			return setLevelsChanged(false);

		if (
			levelListState === "featured" &&
			levelsChanged &&
			!isQuerying &&
			validLevelListState
		) {
			setChangedGameDropdown(false);
			setIsQuerying(true);
			setLevelsToMap([]);
			getFeaturedLevelsPage({
				page,
				name: searchQuery ?? "",
				game: gameParam,
				authors: true,
			})
				.then((response) => {
					if (response?.data) {
						setPageNumberStore((prevState) => {
							// update amount of featured pages
							return {
								...prevState,
								featured: response.data.numberOfPages,
							};
						});
						setNumberOfPages(response.data.numberOfPages);
						setPreviousPage(page);
						setFeaturedLevels(response.data.levels);
						setLevelMessage(response.data.message);
					}
				})
				.catch(() => {
					setLevelMessage("No levels found.");
				})
				.finally(() => {
					setLevelsChanged(false);
					setIsQuerying(false);
				});
		}
	}, [
		levelListState,
		isQuerying,
		levelsChanged,
		page,
		searchQuery,
		gameParam,
		previousPage,
		changedGameDropdown,
		validLevelListState,
	]);

	const searchOrClearLevels = (e) => {
		e.preventDefault();

		// handle search button
		if (showSearchButton) {
			setShowSearchButton(false);
			setPreviousPage(-1);
			setLevelsChanged(true);
			history.push(
				`${props.location.pathname}${
					searchQuery ? `?levelQuery=${searchQuery}` : ""
				}`
			);

			// handle clear button
		} else {
			setSearchQuery("");
			setShowSearchButton(true);
			history.push(props.location.pathname);
		}
	};

	/**
	 * Display either all levels on the site, or all for a specific user,
	 * if one is included in the URL.
	 *
	 * @returns
	 */
	const displayLevels = () => {
		// if there are no levels, say so
		return !levelsToMap?.length || levelsChanged ? (
			// loading spinner

			<div>
				{isQuerying && (
					<div
						className="d-flex justify-content-center"
						id="loading-spinner"
						style={{ marginTop: "15vh", marginBottom: "25vh" }}>
						<Spinner animation="grow" variant="primary" />
					</div>
				)}

				{/* display "no levels found" message if none are found, and this is a general level list, or a user level list with a valid user */}
				{levelMessage !== null &&
					!levelsToMap?.length &&
					!isQuerying && (
						<div className="col-12">
							<div
								className="card"
								id="infoCard"
								style={{
									textAlign: "center",
									marginTop: "1vh",
									marginBottom: "14vh",
								}}>
								<div className="card-body" id="infoCard">
									<h2 className="card-title">
										{levelMessage}
									</h2>
								</div>
							</div>
						</div>
					)}
			</div>
		) : (
			<>
				{/* otherwise, print all the needed levels */}
				{levelsToMap?.map((level) => {
					const author = level?.author;

					return (
						<div
							key={level?._id}
							className="col-lg-6 col-12 map-height-adjust">
							<Link
								onClick={(e) => {
									// let it open in new tab
									if (e.ctrlKey) return;
									e.preventDefault();
									// go to new page
									history.push(
										!randomState
											? `/levels/${level?._id}`
											: `/levels/${level?._id}?gameID=${level?.game}`
									);
									window.scrollTo(0, 0);
								}}
								href={`/levels/${level?._id}`}
								style={{ textDecoration: "none" }}>
								<div
									className={`card clickable ${
										level?.status === FEATURED_LEVEL
											? "featured-hover"
											: "hover-colour"
									}`}
									id="levelCard">
									<div
										className={`card-body 
										${
											level?.status === FEATURED_LEVEL
												? "featured-card"
												: level?.status ===
												  PRIVATE_LEVEL
												? "private-card"
												: level?.status ===
														DELETED_LEVEL &&
												  "deleted-card"
										}
											${level?.hasPlayed === true ? "has-played-level" : "hasnt-played-level"}
											`}
										id="levelCard">
										{/*The level thumbnail is encoded in a Base64 inside the database.*/}
										<ImageStarContainer
											level={level}
											gameProperties={allGames}
										/>

										<h5
											className="card-title"
											title={level?.name}>
											<b>{level?.name}</b>
										</h5>
										<h6 className="card-subtitle mb-2">
											<span className="d-none d-lg-inline">
												Game:&nbsp;
											</span>
											<b>
												<span
													className={`game__${level?.game}`}>
													{
														props.gameProperties?.find(
															(game) =>
																game?.internalID ===
																level?.game
														)?.name
													}
												</span>
											</b>
										</h6>
										<h6 className="card-subtitle mb-2 ">
											Difficulty: {level?.difficulty}
										</h6>
										<h6 className="card-subtitle mb-2 ">
											Comments:{" "}
											{level?.commenters?.length}{" "}
											{level?.commenters?.includes(
												currentUserData?._id
											) && (
												<span className="yellow">
													&#x2713;
												</span>
											)}
										</h6>
										<h6 className="card-subtitle mb-2 ">
											Plays: {level?.plays}{" "}
											{level?.hasPlayed && (
												<span className="yellow">
													&#x2713;
												</span>
											)}
										</h6>
										<h6 className="card-subtitle mb-2 ">
											Rates: {level?.rates?.length}{" "}
											{level?.hasRated && (
												<span className="yellow">
													&#x2713;
												</span>
											)}
										</h6>
										<br />
										<Nav
											style={{
												height: "max-content",
												marginBottom: "12px",
												width: "50%",
											}}>
											<OverlayTrigger
												// @ts-ignore
												animation={true}
												fade={true}
												delay={{ show: 50, hide: 20 }}
												placement="top"
												overlay={
													<Tooltip
														id="tags"
														className="d-none d-lg-block"
														style={{
															opacity: 0.96,
														}}>
														Tags
													</Tooltip>
												}>
												<div
													className="material-icons"
													style={{
														marginRight: "100%",
													}}>
													local_offer
												</div>
											</OverlayTrigger>
											<div
												className="tag-container"
												style={{
													whiteSpace: "pre-wrap",
												}}>
												{level &&
												level.tags &&
												level.tags.length > 0 &&
												level.tags[0].trim() !== "" ? (
													<>
														{level.tags?.map(
															(tag, index) => (
																<React.Fragment
																	key={index}>
																	<span className="tags">
																		{tag}
																	</span>
																	{index <
																		level
																			.tags
																			.length -
																			1 && (
																		<span>
																			{" "}
																		</span>
																	)}
																</React.Fragment>
															)
														)}
													</>
												) : (
													<i className="gray">
														No tags
													</i>
												)}

												{/* show status of level if this is the logged in user's level list, or username of current level author if general level list */}

												{!userID ? (
													<div className="info-bottom">
														{/* move text on public level list to account for featured status, author display */}
														<div
															className={
																level?.status ===
																FEATURED_LEVEL
																	? "info-bottom__adjust-info-bottom-f"
																	: "info-bottom__adjust-info-bottom"
															}>
															By{" "}
															<span
																className="anchor"
																onClick={(
																	e
																) => {
																	e.stopPropagation();
																	// open in new tab with ctrl
																	if (
																		e.ctrlKey
																	)
																		return window.open(
																			`/users/${author?._id}`,
																			"_blank"
																		);
																	// otherwise open in this window
																	e.preventDefault();
																	history.push(
																		`/users/${author?._id}`
																	);
																	window.scrollTo(
																		0,
																		0
																	);
																}}
																title={
																	author?.username
																}>
																{author?.username ??
																	"..."}
															</span>
															{/* list additional contributors */}
															<span
																className="d-none d-lg-inline"
																title={level?.contributors?.join(
																	", "
																)}>
																{
																	// display any additional contributors
																	level
																		?.contributors
																		?.length >
																	0
																		? ", " +
																		  level?.contributors?.join(
																				", "
																		  )
																		: ""
																}
															</span>
														</div>
														{/* if the level is featured, show the status in the public level list. */}
														{level?.status ===
														FEATURED_LEVEL ? (
															<div className="info-bottom__adjust-info-bottom">
																This level is{" "}
																<b>
																	<span className="yellow">
																		{level?.status?.toLowerCase()}
																	</span>
																	<br />
																</b>
															</div>
														) : (
															<span></span>
														)}
													</div>
												) : (
													(currentUserData?._id ===
														level?.author ||
														currentUserData?.isStaff) && (
														<div className="adjust-info-bottom">
															This level is{" "}
															<b>
																<span
																	className={`
														${
															level?.status ===
															FEATURED_LEVEL
																? "yellow"
																: level?.status ===
																  PRIVATE_LEVEL
																? "gray"
																: level?.status ===
																  DELETED_LEVEL
																? "hydrawisp-red"
																: "b"
														}`}>
																	{level?.status?.toLowerCase()}
																</span>
															</b>
														</div>
													)
												)}
											</div>
										</Nav>
									</div>
								</div>
							</Link>
						</div>
					);
				})}

				{/* allow user to change level page, modifiable pagination component is in Pagination.js*/}
				<div className="MuiPagination-root">
					<Pagination
						setContentChanged={setLevelsChanged}
						page={page || 1}
						numberOfPages={numberOfPages || 1}
						type="comment"
						siblingCount={3}
					/>
				</div>
			</>
		);
	};

	const author = levels?.[0]?.author;

	return (
		<div className="container mt-4">
			{!validLevelListState &&
			!window.location.pathname.includes("/levels/user/") ? (
				<GameSelect
					gameProperties={props?.gameProperties}
					setGameParam={setGameParam}
					setCurrentUserData={props?.setCurrentUserData}
				/>
			) : (
				""
			)}
			{/* search functionality, and link to add one if user is logged in */}
			{validLevelListState ? (
				<div className="jumbotron">
					<span className="row">
						<span className="col">
							<h2
								className="display-4"
								style={{ wordBreak: "break-all" }}>
								{userID && currentUserData?._id === userID && (
									<span>
										<a
											href={`/users/${userID}`}
											onClick={(e) => {
												e.preventDefault();
												window.scrollTo(0, 0);
												history.push(
													`/users/${userID}`
												);
											}}>
											&larr;
										</a>
										&nbsp;Your Levels
									</span>
								)}
								{userID &&
								currentUserData?._id !== userID &&
								!isQuerying ? (
									<span>
										<a
											href={`/users/${userID}`}
											onClick={(e) => {
												e.preventDefault();
												window.scrollTo(0, 0);
												history.push(
													`/users/${userID}`
												);
											}}>
											{author
												? author?.username
												: "Back to profile"}
										</a>
										{author ? "'s Levels" : ""}
									</span>
								) : isQuerying &&
								  userID &&
								  userID !== currentUserData?._id ? (
									<Spinner
										animation="grow"
										variant="primary"
									/>
								) : null}
								{!userID && "Browse Levels"}
							</h2>
							<p className="lead">
								You can play, rate & upload levels from this
								page!
							</p>
						</span>
					</span>
					{/* level search functionality */}
					<form onSubmit={searchOrClearLevels}>
						<div className="form-group">
							<div className="input-group mb-3">
								{/* filter by level name */}
								<input
									type="text"
									className="form-search-control"
									id="search"
									name="search"
									maxLength={255}
									placeholder="Search for levels..."
									value={searchQuery}
									disabled={isQuerying}
									onKeyDown={handleKeyPress}
									onChange={(e) => {
										if (!showSearchButton)
											setShowSearchButton(true);
										setSearchQuery(e.target.value);
									}}
								/>
								<OverlayTrigger
									overlay={
										<Tooltip id="searchActions">
											{showSearchButton
												? "Search levels"
												: "Reset search"}
										</Tooltip>
									}
									placement="top">
									<button
										className="btn btn-primary"
										disabled={isQuerying}
										type="button"
										onClick={searchOrClearLevels}
										id="search-button">
										<span className="material-icons align-bottom">
											{showSearchButton
												? "search"
												: "close"}
										</span>
									</button>
								</OverlayTrigger>
							</div>
						</div>
					</form>
					{/* game dropdown */}
					{props.match.params.id ? (
						<>
							<select
								id="selection"
								name="selection"
								className="random-level__form"
								value={
									// check for valid number
									gameParam >= 0 &&
									gameParam < props.gameProperties?.length - 1
										? // if valid, use it as string, otherwise resort to fallback
										  gameParam.toString()
										: "all"
								}
								onChange={(e) => {
									const targetValue: number = parseInt(
										e.target.value
									);
									setGameParam(targetValue);
									setChangedGameDropdown(true);
								}}>
								<option value="-1">All games</option>
								{props.gameProperties?.map((game) => (
									<option
										key={game?._id}
										value={game?.internalID}
										className={`game__${game?.internalID}`}>
										{game?.name}
									</option>
								))}
							</select>
							{/* random levels button */}
							<OverlayTrigger
								overlay={
									<Tooltip id="rollRandom">
										Roll random levels
									</Tooltip>
								}
								placement="top">
								<button
									type="submit"
									className="btn btn-primary btn-lg btn-block random-level__button"
									onClick={() => {
										setLevelsChanged(true);
										setRolledRandomLevels(true);
									}}
									disabled={
										levelListState === "shared" ||
										levelListState === "featured" ||
										isQuerying
									}>
									<span
										className="material-icons"
										style={{
											position: "relative",
											right: "14px",
											bottom: "6px",
											fontSize: "42px",
										}}>
										casino
									</span>
								</button>
							</OverlayTrigger>
						</>
					) : (
						<>
							<button
								className="btn btn-primary__special"
								disabled={isQuerying}
								onClick={(e) => {
									e.preventDefault();
									history.push("/levels");
								}}>
								Change game
							</button>{" "}
							&nbsp;
							<OverlayTrigger
								overlay={
									<Tooltip id="rollRandom">
										Roll random levels
									</Tooltip>
								}
								placement="top">
								<button
									type="submit"
									className="btn btn-primary"
									onClick={() => {
										setLevelsChanged(true);
										setRolledRandomLevels(true);
									}}
									disabled={
										levelListState === "shared" ||
										levelListState === "featured" ||
										isQuerying
									}>
									<span
										className="material-icons"
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											transform: "scale(1.6)",
										}}>
										casino
									</span>
								</button>
							</OverlayTrigger>
						</>
					)}

					<br />
					<br />
					{/* only display button to add a level if the user is logged in */}
					{currentUserData?._id ? (
						<>
							{/* large screens */}
							<div
								className="mx-auto d-flex d-none d-lg-block button-box-main"
								style={{
									width: "max-content",
								}}>
								<a
									href="/levels/add"
									onClick={(e) => {
										e.preventDefault();
										history.push("/levels/add");
										window.scrollTo(0, 0);
									}}>
									<button
										type="button"
										className="btn btn-primary btn-lg btn-block">
										Add a Level
									</button>
								</a>
								{userID && currentUserData?.isStaff && (
									<OverlayTrigger
										overlay={
											<Tooltip id="dbUpdate">
												Issue database update
											</Tooltip>
										}>
										<button
											type="button"
											className="btn btn-primary"
											style={{
												marginLeft: "8px",
												backgroundColor:
													"rgb(34, 139, 34)",
											}}
											onClick={() => {
												toast.info(
													"Updating user levels in the database..."
												);
												updateUserLevels(userID);
											}}>
											<span className="material-icons level-list-icon">
												refresh
											</span>
										</button>
									</OverlayTrigger>
								)}
								<OverlayTrigger
									overlay={
										<Tooltip id="toggleLevelList">
											{levelListState !== "featured"
												? "Previously featured levels"
												: "Return to full levels list"}
										</Tooltip>
									}>
									<button
										className="btn btn-primary__special"
										onClick={() => handleFeaturedFetch()}
										style={{
											marginLeft: "8px",
										}}
										disabled={isQuerying}>
										<span className="material-icons level-list-icon">
											{levelListState !== "featured"
												? "star"
												: "list_alt"}
										</span>
									</button>
								</OverlayTrigger>

								{
									// button to switch between shared access
									userID &&
										(currentUserData?._id === userID ||
											currentUserData?.isStaff) && (
											<OverlayTrigger
												overlay={
													<Tooltip id="toggleLevelList">
														{levelListState !==
														"shared"
															? "Privately shared levels"
															: "Return to full levels list"}
													</Tooltip>
												}>
												<button
													className="btn btn-primary__special"
													onClick={() =>
														handleSharedAccessFetch()
													}
													style={{
														marginLeft: "8px",
													}}
													disabled={isQuerying}>
													<span className="material-icons level-list-icon">
														{levelListState !==
														"shared"
															? "folder_shared"
															: "list_alt"}
													</span>
												</button>
											</OverlayTrigger>
										)
								}
							</div>

							{/* mobile screens */}
							<div
								className="mx-auto d-flex justify-content-center d-lg-none"
								style={{ width: "155px" }}>
								<a
									href="/levels/add"
									onClick={(e) => {
										e.preventDefault();
										history.push("/levels/add");
										window.scrollTo(0, 0);
									}}>
									<button
										type="button"
										className="btn btn-primary btn-lg btn-block">
										Add a Level
									</button>
								</a>
							</div>
							<br className="d-lg-none" />
							{userID && currentUserData?.isStaff && (
								<OverlayTrigger
									overlay={
										<Tooltip id="dbUpdate">
											Issue database update
										</Tooltip>
									}>
									<button
										type="button"
										className="btn btn-primary d-lg-none"
										style={{
											marginLeft: "8px",
											backgroundColor: "rgb(34, 139, 34)",
										}}
										onClick={() => {
											toast.info(
												"Updating user levels in the database..."
											);
											updateUserLevels(userID);
										}}>
										<span className="material-icons level-list-icon">
											refresh
										</span>
									</button>
								</OverlayTrigger>
							)}
							<OverlayTrigger
								overlay={
									<Tooltip id="toggleLevelList">
										{levelListState !== "shared"
											? "Previously featured levels"
											: "Back to full levels list"}
									</Tooltip>
								}>
								<button
									className="btn btn-primary__special d-lg-none"
									onClick={() => handleFeaturedFetch()}
									style={{
										marginLeft: "8px",
									}}
									disabled={isQuerying}>
									<span className="material-icons level-list-icon">
										{levelListState !== "featured"
											? "star"
											: "list_alt"}
									</span>
								</button>
							</OverlayTrigger>
							{
								// button to switch between shared access
								userID &&
									(currentUserData?._id === userID ||
										currentUserData?.isStaff) && (
										<OverlayTrigger
											overlay={
												<Tooltip id="toggleLevelList">
													{levelListState !== "shared"
														? "Privately shared levels"
														: "Back to full levels list"}
												</Tooltip>
											}>
											<button
												className="btn btn-primary__special d-lg-none"
												onClick={() =>
													handleSharedAccessFetch()
												}
												style={{
													marginLeft: "8px",
												}}
												disabled={isQuerying}>
												<span className="material-icons level-list-icon">
													{levelListState !== "shared"
														? "folder_shared"
														: "list_alt"}
												</span>
											</button>
										</OverlayTrigger>
									)
							}
						</>
					) : (
						<OverlayTrigger
							overlay={
								<Tooltip id="toggleLevelList">
									{levelListState !== "featured"
										? "Previously featured levels"
										: "Return to full levels list"}
								</Tooltip>
							}>
							<button
								className="btn btn-primary__special"
								onClick={() => handleFeaturedFetch()}
								style={{
									marginLeft: "8px",
								}}
								disabled={isQuerying}>
								<span className="material-icons level-list-icon">
									{levelListState !== "featured"
										? "star"
										: "list_alt"}
								</span>
							</button>
						</OverlayTrigger>
					)}
				</div>
			) : null}
			<br />

			{/* display all needed levels */}
			<div className="row mx-auto">{displayLevels()}</div>
		</div>
	);
};

const GameSelect = (props) => {
	interface Game {
		name?: string;
		levelCount?: number;
		priority: boolean;
		forceTail: boolean;
	}
	const [selectedGame, setSelectedGame] = useState<Game | null>(null);
	const history = useHistory();

	return (
		<div style={{ overflowX: "hidden" }}>
			<div className="game-filter-select">
				<h1 style={{ margin: "0 auto" }}>
					{selectedGame === null ? (
						"Select a category"
					) : (
						<>
							{selectedGame?.priority ? (
								<span className="wildheart-green">
									[featured]&nbsp;
								</span>
							) : selectedGame?.forceTail ? (
								<span className="gray">[archive]&nbsp;</span>
							) : (
								""
							)}
							{selectedGame?.name} -{" "}
							<span className="yellow">
								{selectedGame?.levelCount} levels
							</span>
						</>
					)}
				</h1>
				<div
					style={{
						width: "85%",
						paddingTop: "24px",
						borderTop: "1px solid #bbb",
					}}>
					<div className="game-list">
						<button
							className="game-list__button btn-primary"
							style={{
								transform: "scale(1)",
								zIndex: "0",
							}}
							onMouseEnter={() =>
								setSelectedGame({
									name: "All games",
									// total level count
									levelCount: props.gameProperties.reduce(
										(acc, cur) => acc + cur.levelCount,
										0
									),
									priority: false,
									forceTail: false,
								})
							}
							onMouseLeave={() => setSelectedGame(null)}
							onClick={() => {
								//! go to the global level list
								if (props.setGameParam) {
									// go to the level list
									history.push(`/all/levels`);
									// update the game param
									return props.setGameParam(-1);
								}
							}}>
							<h1>All games</h1>
						</button>
						{props.gameProperties?.map((game) => {
							const passedProps = {
								game: game,
								...props,
								setGameParam: props?.setGameParam,
								setCurrentUserData: props?.setCurrentUserData,
								setSelectedGame: setSelectedGame,
							};
							return (
								<React.Fragment key={game?._id}>
									<Game {...passedProps} />
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Levels;
