import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { toast } from "react-toastify";

const PageNotFound = () => {
	const [pageBack, setPageBack] = useState(0);
	const [game, setGame] = useState(1);

	const history = useHistory();

	useEffect(() => {
		document.title = "Page Not Found - Level Share Square";

		if (pageBack !== 0) {
			setTimeout(() => {
				setPageBack(pageBack + 1);
			}, 40);
			if (pageBack > 20) return history.push("/");
		}
	}, [pageBack, history]);

	return (
		<div
			className="jumbotron page-not-found"
			style={{
				opacity: pageBack > 0 ? 0 : 1,
				transition: "opacity 1s ease-in-out",
			}}>
			<div className="page-not-found__text">
				<h1 className="page-not-found__text__title">
					<b>OUT OF BOUNDS</b>
				</h1>
				<h2 className="page-not-found__text__text">
					Either{" "}
					<a href="#!" onClick={() => setPageBack(1)}>
						go back
					</a>{" "}
					or play some...
					<br />
					<select
						className="form"
						style={{ borderRadius: "7px", marginTop: "4px" }}
						value={game}
						onChange={(e) => setGame(parseInt(e.target.value))}>
						<option value={1}>tic tac toe</option>
						<option value={2}>snake</option>
					</select>
				</h2>
				<hr style={{ margin: "10px" }} />
				{game === 1 && <TicTacToe />}
				{game === 2 && <Snake />}
				{/*<MineSweeper />*/}
			</div>
		</div>
	);
};

const Snake = () => {
	const initialState = () => {
		const array = Array.from({ length: 10 }, () => Array(10).fill(0));
		array[5][5] = 3;
		array[4][5] = 2;
		array[3][5] = 1;
		return array;
	};
	const initialLength = 3;
	const [board, setBoard] = useState<number[][]>(initialState);
	const [direction, setDirection] = useState<string>("up");
	const [previousDirection, setPreviousDirection] = useState<string>("up");
	const [snakeLength, setSnakeLength] = useState<number>(initialLength);
	const [resetState, setResetState] = useState<boolean>(false);
	const [gameActive, setGameActive] = useState<boolean>(false);
	const [gameOver, setGameOver] = useState<boolean>(false);
	const [stateChanged, setStateChanged] = useState(true);
	const [apple, setApple] = useState<number>(-1);
	const [hasGeneratedApple, setHasGeneratedApple] = useState<boolean>(false);

	const generateApple = useCallback(
		(boardState) => {
			// check if the apple needs updating

			const availableRows: number[] = [];
			// check for available rows
			boardState.forEach((row, index) => {
				const hasSpace = row.some((cell) => cell === -1);
				// update array
				if (hasSpace) availableRows.push(index);
			});
			// pick a random row
			const selectedRow = Math.floor(
				Math.random() * availableRows?.length
			);
			// map the cells
			const availableIndices: number[] = [];
			boardState?.[selectedRow].forEach((cell, index) => {
				if (cell === -1) availableIndices.push(index);
				return;
			});
			// pick a random one
			const selectedIndex = Math.floor(
				Math.random() * availableIndices?.length
			);
			// now set the apple
			const newApple =
				selectedRow * board.length + availableIndices?.[selectedIndex];
			setApple(newApple);
		},
		[board.length]
	);

	const handleReset = useCallback(() => {
		// warn before resetting
		if (!resetState && !gameOver) {
			setResetState(true);
			toast.warn("Click again to reset.");
			return setTimeout(() => setResetState(false), 2000);
		}
		// reset
		setDirection("up");
		setResetState(false);
		setGameActive(false);
		setGameOver(false);
		setBoard(initialState);
		setSnakeLength(initialLength);
		generateApple(board);
	}, [resetState, generateApple, gameOver, board]);

	useEffect(() => {
		// handle a key input
		const handleKeyDown = (e) => {
			if (e.ctrlKey !== true || e.code !== "KeyR") e.preventDefault();

			// refresh
			if (e.code === "KeyR") return handleReset();

			// reset game automatically when pressing space
			if ("Space" === e.code && gameOver) return handleReset();

			// unpause the game
			if (
				[
					"KeyW",
					"ArrowUp",
					"KeyS",
					"ArrowDown",
					"KeyA",
					"ArrowLeft",
					"KeyD",
					"ArrowRight",
					"Space",
				].includes(e.code) &&
				!gameActive
			)
				return setGameActive(true);

			// pause
			if ("Space" === e.code && gameActive) return setGameActive(false);

			// up
			if (
				["KeyW", "ArrowUp"].includes(e.code) &&
				previousDirection !== "down"
			)
				return setDirection("up");
			// down
			if (
				["KeyS", "ArrowDown"].includes(e.code) &&
				previousDirection !== "up"
			)
				return setDirection("down");
			// left
			if (
				["KeyA", "ArrowLeft"].includes(e.code) &&
				previousDirection !== "right"
			)
				return setDirection("left");
			// right
			if (
				["KeyD", "ArrowRight"].includes(e.code) &&
				previousDirection !== "left"
			)
				return setDirection("right");
		};
		//add an event listener for keyboard input
		window.addEventListener("keydown", handleKeyDown);
		// remove it
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [handleReset, gameActive, direction, previousDirection, gameOver]);

	useEffect(() => {
		let interval;
		// interval for checking for new board state
		if (gameActive && !gameOver) {
			interval = setInterval(() => {
				setStateChanged(true);
			}, 140);
		}

		if (gameActive && snakeLength === 99) {
			clearInterval(interval);
			setGameActive(false);
		}

		return () => clearInterval(interval);
	}, [gameActive, gameOver, snakeLength]);

	useEffect(() => {
		if (stateChanged) {
			setStateChanged(false);
			setBoard((state) => {
				let headRow, headCell;
				// map all cells and reduce their count by 1 if its not empty
				let newState = state.map((row, rowNumber) =>
					row.map((cell, cellNumber) => {
						if (state[rowNumber][cellNumber] === snakeLength) {
							// retrieve the location of the head
							headRow = rowNumber;
							headCell = cellNumber;
						}
						// if not 0, update
						if (cell !== -1) cell -= 1;
						return cell;
					})
				);
				// place down the new head based upon direction
				if (direction === "up") headRow += 1;
				if (direction === "down") headRow -= 1;
				if (direction === "left") headCell -= 1;
				if (direction === "right") headCell += 1;
				setPreviousDirection(direction);
				// define whether the apple is touched
				let appleTouched = false;
				// check if the head is touching an apple
				if (apple === headRow * board.length + headCell) {
					setSnakeLength((prev) => (prev += 1));
					setApple(-1);
					newState = state;
					appleTouched = true;
				}
				// update the location of the head
				if (
					newState?.[headRow]?.[headCell] > 0 ||
					newState?.[headRow]?.[headCell] === undefined
				) {
					// end the game
					toast.error("Game over!");
					setGameActive(false);
					setGameOver(true);
					// set to snake length
				} else
					newState[headRow][headCell] = appleTouched
						? snakeLength + 1
						: snakeLength;
				// return new state
				return newState;
			});
		}
	}, [
		stateChanged,
		direction,
		snakeLength,
		apple,
		board.length,
		generateApple,
	]);

	// generate an apple upon render
	useEffect(() => {
		if (!hasGeneratedApple || apple === -1 || isNaN(apple)) {
			generateApple(board);
			setHasGeneratedApple(true);
		}
	}, [generateApple, hasGeneratedApple, board, apple]);

	return (
		<>
			<div style={{ fontSize: "2vh" }}>
				Score: {snakeLength - initialLength}
			</div>
			<div
				className="board-box"
				style={{
					flexFlow: "column-reverse",
					padding: "0.8vh",
					borderRadius: "3%",
				}}>
				{board?.map((row, key) => {
					return (
						<div className="game-grid-row" key={"row" + key}>
							{row?.map((cell, index) => {
								const cellNumber = key * board.length + index;
								const isHead =
									board[key][index] === snakeLength;
								const isBody = board[key][index] > 0;
								const name = `game-grid-cell`;
								const isApple = apple === cellNumber;
								return (
									<div className={name} key={cellNumber}>
										{gameOver ? (
											<div className={name + "__dead"} />
										) : isHead ? (
											<div className={name + "__head"} />
										) : isBody ? (
											<div className={name + "__body"} />
										) : isApple ? (
											<div className={name + "__apple"} />
										) : null}
									</div>
								);
							})}
						</div>
					);
				})}
				{!gameActive || gameOver ? (
					<h1 className="overlay-menu">
						{snakeLength === 99
							? "Wowie zowie!"
							: gameOver
							? "Owies."
							: "Press arrow keys or space"}
					</h1>
				) : null}
			</div>
			<div
				style={{
					display: "flex",
					gap: "8px",
					flexFlow: "row",
					justifyContent: "center",
					marginTop: "8px",
				}}>
				<button
					className={`btn btn-primary${resetState ? "__danger" : ""}`}
					style={{ fontSize: "2vh" }}
					onClick={() => handleReset()}>
					Reset
				</button>
			</div>
		</>
	);
};

const TicTacToe = () => {
	const [turnOwner, setTurnOwner] = useState("O");
	const [selectedPlayer, setSelectedPlayer] = useState("O");
	const [turnNumber, setTurnNumber] = useState(1);
	const [resetState, setResetState] = useState(false);
	const [winner, setWinner] = useState("");
	const [buttons, setButtons]: any = useState([
		[{ a: "" }, { b: "" }, { c: "" }],
		[{ a: "" }, { b: "" }, { c: "" }],
		[{ a: "" }, { b: "" }, { c: "" }],
	]);

	// decide if there is a winner
	const decideWinner = (board) => {
		const inversedBoard = [[], [], []];
		let playerWin = "";
		board.forEach((column: any) => {
			// set a map
			const entryMap = new Map([
				["a", 0],
				["b", 1],
				["c", 2],
			]);
			// invert the board
			column.forEach((entry: any) => {
				// loop over entries
				for (const [string, number] of entryMap) {
					const key = Object.keys(entry)[0];
					// @ts-ignore
					if (key === string) inversedBoard[number].push(entry[key]);
				}
			});
			// check for each player
			["X", "O"].forEach((player) => {
				// win for colmn
				const win1 = column.every((entry) => {
					return entry[Object.keys(entry)[0]] === player;
				});
				// win for row
				const win2 = inversedBoard.some(
					(array) =>
						array?.length === 3 &&
						array.every((value) => value === player)
				);
				// win diagonal top left bottom right
				const win3 = [0, 1, 2].every((number) => {
					const cell = board[number][number];
					// @ts-ignore
					return cell[Object.keys(cell)] === player;
				});
				// win diagonal top right bottom left
				const win4 = [0, 1, 2].every((number) => {
					let number2 = 1;
					if (number === 0) number2 = 2;
					if (number === 2) number2 = 0;
					const cell = board[number][number2];
					// @ts-ignore
					return cell[Object.keys(cell)] === player;
				});
				// see if theres a winner
				if (win1 || win2 || win3 || win4) playerWin = player;
			});
		});
		if (playerWin) setWinner(playerWin);
	};

	useEffect(() => {
		if (winner !== "") {
			if (selectedPlayer === winner) toast.success("You win!");
			else toast.error("You lost to random number generation, yippers!");
		}
	}, [winner, selectedPlayer]);

	// handle clicking
	const handleClick = useCallback(
		(index, button) => {
			setButtons((prevState) => {
				const array = prevState[index];
				const buttonIndex = array.findIndex((entry) => {
					const key = Object.keys(entry)[0];
					return key === button;
				});
				array[buttonIndex] = { [button]: turnOwner };
				prevState[index] = array;
				decideWinner(prevState);
				return prevState;
			});
			setTurnOwner((prev) => (prev === "X" ? "O" : "X"));
			setTurnNumber((prev) => prev + 1);
		},
		[turnOwner]
	);

	// handle the click of the opponent
	useEffect(() => {
		if (!winner && turnOwner !== selectedPlayer) {
			const availableSpaces: number[][] = [];
			buttons?.forEach((col, index) =>
				col.forEach((entry, key) => {
					const objectKey = Object.keys(entry);
					// @ts-ignore
					const value = entry[objectKey];
					if (value === "") availableSpaces.push([index, key]);
				})
			);
			if (!availableSpaces?.length) return;
			const randomIndex = Math.floor(
				Math.random() * availableSpaces.length
			);
			const selectedCell = availableSpaces[randomIndex];
			const column = selectedCell[0];
			const index = selectedCell[1];
			// @ts-ignore
			const specificButton = buttons[column][index];
			const buttonId = Object.keys(specificButton)[0];
			setTimeout(() => handleClick(column, buttonId), 400);
		}
	}, [turnOwner, selectedPlayer, buttons, handleClick, winner]);

	// reset the board
	const reset = (changePlayer) => {
		if (!resetState) {
			setResetState(true);
			toast.warn("Click again to reset board");
			setTimeout(() => {
				setResetState(false);
			}, 2000);
			return;
		}
		setTurnNumber(1);
		setButtons([
			[{ a: "" }, { b: "" }, { c: "" }],
			[{ a: "" }, { b: "" }, { c: "" }],
			[{ a: "" }, { b: "" }, { c: "" }],
		]);
		setWinner("");
		// change turn owner
		if (changePlayer === true) {
			setSelectedPlayer((prev) => (prev === "O" ? "X" : "O"));
		}
		setTurnOwner("O");
		setResetState(false);
	};

	return (
		<>
			<div style={{ fontSize: "1.5vh" }}>
				<b>You are playing as {selectedPlayer}</b>
				<br />

				{winner ? (
					<span>
						Winner: <span className="yellow">{winner}</span>
					</span>
				) : turnNumber === 10 && !winner ? (
					<span>
						<span className="blue">Draw.</span>
					</span>
				) : (
					<span>
						It is{" "}
						{turnOwner === selectedPlayer
							? "your"
							: `${turnOwner}'s`}{" "}
						turn.
					</span>
				)}
			</div>
			<div className="board-box unselectable">
				{buttons?.map((array, index) => (
					<div className="board" key={index}>
						{array.map((btn, key) => {
							// create a key
							const buttonId = Object.keys(btn)[0];
							const clicked = ["X", "O"].includes(btn[buttonId]);
							const noWinner = winner === "";
							const yourturn = turnOwner === selectedPlayer;
							return (
								<button
									className={`board-field ${
										clicked || !noWinner || !yourturn
											? "Q"
											: ""
									}hover-${turnOwner.toLowerCase()}`}
									onClick={() => {
										if (!clicked && noWinner && yourturn) {
											handleClick(index, buttonId);
										}
									}}
									key={buttonId + key}>
									<div
										style={{
											height: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										{btn[buttonId]}
									</div>
								</button>
							);
						})}
					</div>
				))}
				{winner ? (
					<h1 className="overlay-menu">
						{winner === selectedPlayer ? "You win!" : "lmao"}
					</h1>
				) : !winner && turnNumber === 10 ? (
					<h1 className="overlay-menu">A draw -.-</h1>
				) : null}
			</div>
			<div
				style={{
					display: "flex",
					gap: "8px",
					flexFlow: "row",
					justifyContent: "center",
					marginTop: "8px",
				}}>
				<button
					className={`btn btn-primary${resetState ? "__danger" : ""}`}
					style={{ fontSize: "2vh" }}
					onClick={() => reset(false)}>
					Reset
				</button>
				<button
					className={`btn btn-primary${
						resetState ? "__danger" : "__special"
					}`}
					style={{ fontSize: "2vh" }}
					onClick={() => reset(true)}>
					Change player
				</button>
			</div>
		</>
	);
};

// const MineSweeper = () => {
// 	const [boardSize, setBoardSize] = useState(10);
// 	const initialState: () => any[][] = useCallback(
// 		() => Array.from({ length: boardSize }, () => Array(boardSize).fill(0)),
// 		[boardSize]
// 	);
// 	const [board, setBoard] = useState(initialState);
// 	const [mineCount, setMineCount] = useState(10);
// 	const [resetState, setResetState] = useState(false);
// 	const [boardChanged, setBoardChanged] = useState(true);
// 	const handleReset = () => {
// 		// warn before resetting
// 		if (!resetState) {
// 			setResetState(true);
// 			toast.warn("Click again to reset.");
// 			return setTimeout(() => setResetState(false), 2000);
// 		}
// 		// reset
// 		setResetState(false);
// 		setBoard(initialState);
// 		setBoardChanged(true);
// 	};

// 	// useeffect hook for generating a random board
// 	useEffect(() => {
// 		if (boardChanged) {
// 			// define a base array
// 			const stateRef: any[][] = initialState();
// 			const indexArray = Array.from({ length: boardSize ** 2 }).map(
// 				(_, index) => index
// 			);
// 			stateRef.forEach((row, index) =>
// 				row.forEach(
// 					(_, key) =>
// 						(stateRef[index][key] = {
// 							value: 0,
// 							hidden: true,
// 							processed: false,
// 						})
// 				)
// 			); // define the offset values
// 			const offsets = [
// 				[-1, -1],
// 				[-1, 0],
// 				[-1, 1],
// 				[0, -1],
// 				[0, 1],
// 				[1, -1],
// 				[1, 0],
// 				[1, 1],
// 			];
// 			const numRows = stateRef.length;
// 			const numCols = stateRef[0].length;
// 			// loop over the minecount
// 			for (let i = mineCount; i > 0; i--) {
// 				// random number
// 				const randomIndex = Math.floor(
// 					indexArray?.length * Math.random()
// 				);
// 				// get the space on the board
// 				const row = Math.floor(randomIndex / boardSize);
// 				const cell = randomIndex - row * boardSize;
// 				// splice it
// 				stateRef[row][cell].value = -1;
// 				indexArray.splice(randomIndex, 1);
// 			}

// 			// now set the neighbouring numbers to the amount of mines there are nearby
// 			stateRef.forEach((fieldRow, index) => {
// 				fieldRow.forEach((fieldCell, key) => {
// 					if (fieldCell.value === -1) {
// 						// update the array
// 						for (const [dx, dy] of offsets) {
// 							const newX = index + dx;
// 							const newY = key + dy;
// 							// Check if the neighboring cell is within bounds
// 							if (
// 								newX >= 0 &&
// 								newX < numRows &&
// 								newY >= 0 &&
// 								newY < numCols &&
// 								stateRef[newX][newY].value !== -1
// 							) {
// 								// Update neighboring cell value
// 								stateRef[newX][newY].value += 1;
// 							}
// 						}
// 					}
// 				});
// 			});
// 			setBoard(stateRef);
// 			setBoardChanged(false);
// 		}
// 	}, [boardChanged, initialState, boardSize, mineCount]);

// 	// handle the click of a cell
// 	const handleClick = (rowIndex, colIndex) => {
// 		setBoard((prevState) => {
// 			// Create a new copy of the prevState array
// 			const newState = [...prevState];
// 			// Update the cell in the new copy of the row array
// 			newState[rowIndex][colIndex].hidden = false;
// 			// get reference
// 			const numRows = newState.length;
// 			const numCols = newState[0].length;
// 			// define the offset values
// 			const offsets = [
// 				[-1, 0],
// 				[0, -1],
// 				[0, 1],
// 				[1, 0],
// 			];
// 			// check if surrounding values are zeros
// 			let hasMadeChanges = false;
// 			while (!hasMadeChanges) {
// 				hasMadeChanges = true;
// 				newState.forEach((row, rIndex) =>
// 					row.forEach((cell, cIndex) => {
// 						if (
// 							cell.hidden === false &&
// 							cell.processed === false &&
// 							cell.value === 0
// 						) {
// 							for (const [dx, dy] of offsets) {
// 								const newX = cIndex + dx;
// 								const newY = rIndex + dy;
// 								// Check if the neighboring cell is within bounds
// 								if (
// 									newX >= 0 &&
// 									newX < numRows &&
// 									newY >= 0 &&
// 									newY < numCols &&
// 									newState[newX][newY].value !== -1 &&
// 									newState[newX][newY].hidden === true
// 								) {
// 									// Update neighboring cell value
// 									newState[newX][newY].hidden = false;
// 									hasMadeChanges = false;
// 								}
// 							}
// 							newState[rIndex][cIndex].processed = true;
// 						}
// 					})
// 				);
// 			}

// 			// Return the new state
// 			return newState;
// 		});
// 	};

// 	return (
// 		<>
// 			<div style={{ fontSize: "2vh" }}>Minesweeper</div>
// 			<div
// 				className="board-box"
// 				style={{
// 					flexFlow: "column-reverse",
// 					borderRadius: "8px",
// 				}}>
// 				{board?.map((row, key) => {
// 					return (
// 						<div
// 							className="game-grid-row"
// 							key={"row" + key}
// 							style={{
// 								border: "0.04rem solid rgba(255, 255, 255, 0.6)",
// 							}}>
// 							{row.map((cell, index) => {
// 								const elementNumber =
// 									key * board?.length + index;

// 								return (
// 									<div
// 										key={elementNumber}
// 										style={{
// 											display: "flex",
// 											alignItems: "center",
// 											justifyContent: "center",
// 										}}
// 										onClick={() => {
// 											handleClick(key, index);
// 											cell.hidden = false;
// 										}}
// 										className={`game-grid-cell game-grid-cell${
// 											cell.hidden
// 												? "__hidden-sweeper"
// 												: ""
// 										}`}>
// 										{cell.hidden ? null : cell.value}
// 									</div>
// 								);
// 							})}
// 						</div>
// 					);
// 				})}
// 			</div>
// 			<div
// 				style={{
// 					display: "flex",
// 					gap: "8px",
// 					flexFlow: "row",
// 					justifyContent: "center",
// 					marginTop: "8px",
// 				}}>
// 				<button
// 					className={`btn btn-primary${resetState ? "__danger" : ""}`}
// 					style={{ fontSize: "2vh" }}
// 					onClick={() => handleReset()}>
// 					Reset
// 				</button>
// 			</div>
// 		</>
// 	);
// };

export default PageNotFound;
