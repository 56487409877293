import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../App";
import {
	getLevelCommentsPage,
	getUserRates,
	removeLevelRate,
} from "../../../api";
import {
	Button,
	Modal,
	OverlayTrigger,
	Spinner,
	Tooltip,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Pagination from "../../Pagination/Pagination";
import moment from "moment";
import MapStars from "../../Insertions/MapStars";
import ReadMore from "../../Insertions/ReadMore";

let justDeletedRate = false;

const StatsOverlay = (props) => {
	const currentUserData = useContext(UserContext);
	const user = props.user;
	const userID = props?.user?._id;
	const pageOwner = userID === currentUserData?._id && currentUserData?._id;
	const history = useHistory();

	// states for user rates
	const [userRatesModalShow, setUserRatesModalShow] = useState(false);
	const [ratedLevelsChanged, setRatedLevelsChanged] = useState(false);
	const [ratedLevels, setRatedLevels] = useState<any[]>([]);
	const [prevRatepage, setPrevRatepage] = useState(-1);
	const [privateRates, setPrivateRates] = useState(false);
	const [dateHover, setDateHover] = useState(false);
	const [numberOfRatePages, setNumberOfRatePages] = useState(1);
	// define the limit and maximum, including what to display

	// responsiveness check
	const canSeeLevels = ratedLevels?.length > 0;

	// last online
	const currentTime = new Date();
	const lastOnlineTime = new Date(user?.lastOnline);
	currentTime.setMinutes(currentTime.getMinutes() - 8);
	const isNowOnline =
		currentTime.getTime() - lastOnlineTime.getTime() < 15 * 60 * 1000 ||
		currentUserData?._id === userID;
	user.idle = currentUserData?._id === userID ? false : user?.idle;

	// define stats to return
	const userLevels = (!props.levelMessage ? "..." : props.levels) || 0;
	const userRates =
		user?.rates?.length > 1 ? user?.rates?.length : user?.rates || 0;
	const userPlays = user?.levels_played || 0;
	const userFollowers = user?.followers || 0;
	const userReviews =
		props.levelCommentCount === 0 && !props.levelMessage
			? "..."
			: props.levelCommentCount;

	// useEffect hook for getting levels for rates
	useEffect(() => {
		if (
			(prevRatepage === props.ratepage || userRates < 1) &&
			!justDeletedRate
		)
			return setRatedLevelsChanged(false);

		if (userRatesModalShow === true && ratedLevelsChanged) {
			// request
			setRatedLevelsChanged(false);
			getUserRates(userID, props.ratepage)
				.then((response) => {
					if (response?.data) {
						//update state
						setPrevRatepage(props.ratepage);
						setRatedLevels(response?.data?.levels);
						setPrivateRates(response.data.private);
						setNumberOfRatePages(response?.data?.numberOfPages);
					}
				})
				.catch(
					() =>
						currentUserData?.isStaff !== true &&
						!pageOwner &&
						user?._id !== setPrivateRates(true)
				);

			if (justDeletedRate) justDeletedRate = false;
		}
	}, [
		userRatesModalShow,
		ratedLevelsChanged,
		props.ratepage,
		userID,
		prevRatepage,
		user?.rates,
		currentUserData?.isStaff,
		pageOwner,
		user?._id,
		userRates,
	]);

	// modal for showing user rates
	const userRatesModal = () => {
		return (
			<Modal
				show={userRatesModalShow}
				onHide={() => setUserRatesModalShow(false)}
				size={
					userRatesModalShow
						? canSeeLevels
							? "lg"
							: undefined
						: "sm"
				}
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span className="modal-fill">
					<Modal.Body
						className="center-textoutput"
						style={{
							overflowY: "auto",
							fontSize: "20px",
							maxHeight: "80vh",
							filter: "drop-shadow(4px 4px 4px rgb(46, 45, 45))",
						}}>
						{userRatesModalShow ? (
							<>
								<h1
									style={{ textAlign: "center" }}
									className="yellow">
									{props.currentUserData?._id === userID
										? "Your"
										: `${user?.username}'s`}{" "}
									rates
								</h1>
								<hr style={{ marginBottom: "0px" }} />
								{ratedLevels?.length && userRates > 0 ? (
									ratedLevels?.map((level, index) => (
										<div key={level?._id} className="row">
											<MappedRates
												index={
													index +
													20 * (props.ratepage - 1)
												}
												level={level}
												canSeeLevels={canSeeLevels}
												currentUserData={
													props.currentUserData
												}
												userID={userID}
												setUser={props.setUser}
												setRatedLevelsChanged={
													setRatedLevelsChanged
												}
											/>
										</div>
									))
								) : !ratedLevels?.length &&
								  userRates > 0 &&
								  !privateRates ? (
									<div className="d-flex justify-content-center">
										<Spinner
											animation="grow"
											variant="primary"
										/>
									</div>
								) : !privateRates ? (
									<div style={{ marginTop: "16px" }}>
										{props.currentUserData?._id === userID
											? "You haven't"
											: "This user hasn't"}{" "}
										rated any levels yet.
									</div>
								) : (
									<div style={{ marginTop: "16px" }}>
										{!currentUserData?._id
											? "Please log in to use this feature."
											: "You can't view other users' rates"}
									</div>
								)}
								<br />
								{userRates > 0 && !privateRates ? (
									<Pagination
										type="rates"
										numberOfPages={numberOfRatePages}
										ratepage={props.ratepage}
										setContentChanged={
											setRatedLevelsChanged
										}
									/>
								) : null}
							</>
						) : (
							<h4>Closing...</h4>
						)}
					</Modal.Body>
					<Modal.Footer
						className="mx-auto"
						style={{
							filter: "drop-shadow(4px 4px 4px rgb(46, 45, 45))",
						}}>
						<Button
							style={{ margin: "0 auto" }}
							onClick={() => setUserRatesModalShow(false)}>
							Close
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	//! variables for user comments
	const [commentModalShow, setCommentModalShow] = useState<boolean>(false);
	const [commentSort, setCommentSort] = useState("new");
	const [prevCommentPage, setPrevCommentPage] = useState(-1);
	const [numberOfCommentPages, setNumberOfCommentPages] = useState<number>(1);
	const [levelCommentsChanged, setLevelCommentsChanged] =
		useState<boolean>(false);
	const [levelComments, setLevelComments] = useState<Record<string, any>[]>(
		[]
	);

	// useeffect hook for fetchin level comments
	useEffect(() => {
		if (prevCommentPage === props.reviewpage)
			return setLevelCommentsChanged(false);

		if (commentModalShow === true && levelCommentsChanged) {
			// request
			setLevelCommentsChanged(false);
			getLevelCommentsPage(userID, {
				page: props.reviewpage,
				type: "user",
				sort: commentSort,
			}).then((response) => {
				if (response?.data) {
					//update state
					setPrevCommentPage(props.reviewpage);
					setLevelComments(response?.data?.levelComments);
					setNumberOfCommentPages(response?.data?.numberOfPages);
				}
			});
		}
	}, [
		prevCommentPage,
		props.reviewpage,
		commentModalShow,
		levelCommentsChanged,
		userID,
		commentSort,
	]);

	// modal for showing user comments/reviews
	const userCommentsModal = () => {
		return (
			<Modal
				show={commentModalShow}
				onHide={() => setCommentModalShow(false)}
				size={"lg"}
				aria-labelledby="contained-modal-title-vcenter"
				className="popup-modal"
				centered>
				<span className="modal-fill">
					<Modal.Body
						className="center-textoutput"
						style={{
							overflowY: "auto",
							fontSize: "20px",
							maxHeight: "80vh",
							filter: "drop-shadow(4px 4px 4px rgb(46, 45, 45))",
							borderBottom: "1px solid white",
						}}>
						{commentModalShow ? (
							<>
								<h1
									style={{
										textAlign: "center",
										width: "100%",
										paddingBottom: "5px",
										marginBottom: "12px",
										borderBottom: "2px solid white",
									}}
									className="yellow">
									{props.currentUserData?._id === userID
										? "Your"
										: `${user?.username}'s`}{" "}
									level comments/reviews
								</h1>
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										flexFlow: "column",
										gap: "24px",
									}}>
									{levelComments?.length && userReviews ? (
										levelComments?.map((comment) => (
											<div
												key={comment?._id}
												style={{ width: "100%" }}>
												<MappedComments
													comment={comment}
												/>
											</div>
										))
									) : !levelComments?.length &&
									  userReviews ? (
										<div className="d-flex justify-content-center">
											<Spinner
												animation="grow"
												variant="primary"
											/>
										</div>
									) : (
										<div style={{ marginTop: "16px" }}>
											{props.currentUserData?._id ===
											userID
												? "You haven't"
												: "This user hasn't"}{" "}
											commented on any levels yet.
										</div>
									)}
								</div>
								<br />
								{userReviews ? (
									<Pagination
										type="review"
										numberOfPages={numberOfCommentPages}
										ratepage={props.reviewpage}
										setContentChanged={
											setLevelCommentsChanged
										}
									/>
								) : null}
							</>
						) : (
							<h4>Closing...</h4>
						)}
					</Modal.Body>
					<Modal.Footer
						className="mx-auto modal-buttons"
						style={{
							border: "unset",
							filter: "drop-shadow(4px 4px 4px rgb(46, 45, 45))",
						}}>
						<Button
							onClick={() => {
								setPrevCommentPage(-1);
								setCommentSort((prev) =>
									prev === "priority" ? "new" : "priority"
								);
								setLevelCommentsChanged(true);
								setLevelComments([]);
							}}>
							Sorted by{" "}
							{commentSort === "new" ? "newest" : "most upvoted"}
						</Button>
						<Button onClick={() => setCommentModalShow(false)}>
							Close
						</Button>
					</Modal.Footer>
				</span>
			</Modal>
		);
	};

	const statsMap: any = [
		{
			icon: "rocket_launch",
			tooltip: "Levels",
			value: userLevels,
			condition: true,
			href: `/levels/user/${userID}`,
			action: (e) => {
				e.preventDefault();
				history.push(`/levels/user/${userID}`);
			},
		},
		{
			icon: "star",
			tooltip: "Rates",
			value: userRates,
			condition:
				userID === currentUserData?._id || currentUserData?.isStaff,
			action: (e) => {
				e.preventDefault();
				setUserRatesModalShow(true);
			},
		},
		{
			icon: "sports_esports",
			tooltip: "Plays",
			value: userPlays,
			condition: null,
		},
		{
			icon: "rate_review",
			tooltip: "Reviews",
			value: userReviews,
			condition: true,
			action: (e) => {
				e.preventDefault();
				setCommentModalShow(true);
			},
		},
		{
			icon: "groups",
			tooltip: "Followers",
			value: userFollowers,
			condition: null,
		},
	];

	const displayStats = (percentage) => {
		return statsMap.map((stat, key) => (
			<div
				style={{
					left: `calc(7% + ${key * percentage}%)`,
					position: "absolute",
					textAlign: "center",
				}}
				key={key}>
				{stat.condition === true ? (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id={stat.tooltip}>
									{stat.tooltip}
								</Tooltip>
							}>
							<span
								className={`material-icons ${
									stat?.condition === true ? "clickable" : ""
								}`}
								onClick={
									stat?.condition === true
										? stat.action
										: null
								}
								style={{ transform: "scale(1.25)" }}>
								{stat.icon}
							</span>
						</OverlayTrigger>
						<br />
						<a
							href={stat?.href ? stat.href : "#!"}
							onClick={stat.action}
							style={{
								opacity: props?.showDisplayRoles ? 1 : 0,
								transition: "opacity 0.6s ease-in-out",
							}}>
							{stat.value}
						</a>
					</>
				) : (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip id={stat.tooltip}>
									{stat.tooltip}
								</Tooltip>
							}>
							<span
								className={`material-icons ${
									stat?.condition === true ? "clickable" : ""
								}`}
								onClick={
									stat?.condition === true
										? stat.action
										: null
								}
								style={{ transform: "scale(1.25)" }}>
								{stat.icon}
							</span>
						</OverlayTrigger>
						<br />
						<span
							className="yellow"
							style={{
								opacity: props?.showDisplayRoles ? 1 : 0,
								transition: "opacity 0.6s ease-in-out",
							}}>
							{stat.value}
						</span>
					</>
				)}
			</div>
		));
	};

	return (
		<div className="profile__upper__pane unselectable">
			<div className="profile__upper__pane__stats">
				<span className="d-lg-none">{displayStats(20)}</span>
				<span className="d-none d-lg-block">{displayStats(8)}</span>
			</div>
			<div className="profile__upper__pane__online d-none d-lg-block">
				<div
					style={{ display: "inline-block", pointerEvents: "auto" }}
					onMouseOver={() => {
						!dateHover && setDateHover(true);
					}}
					onMouseLeave={() => setDateHover(false)}>
					{dateHover ? (
						<u>JOINED ON</u>
					) : (
						<u>
							{!isNowOnline ||
							user?.inGame === null ||
							!user?.inGame
								? "LAST ONLINE"
								: "CURRENTLY IN-GAME"}
						</u>
					)}
					<br />
					{dateHover ? (
						<b
							style={{
								textShadow: "0 0 1px lightgrey",
								filter: "drop-shadow(0 0 1px lightgrey)",
							}}>
							{moment(user?.joinDate).format("MMM D, YYYY")}
						</b>
					) : (
						<>
							<div
								className="profile__upper__pane__activity"
								style={{
									backgroundImage:
										isNowOnline === true
											? user?.inGame !== null
												? "radial-gradient(circle, rgb(58, 232, 248), rgb(19, 167, 247))"
												: user?.idle
												? "radial-gradient(circle, rgb(214, 149, 9), rgb(194, 103, 46))"
												: "radial-gradient(circle, rgb(12, 153, 16), rgb(32, 105, 9))"
											: "radial-gradient(circle, rgb(77, 76, 76), rgb(28, 28, 28))",
								}}
							/>
							<span>
								{isNowOnline ? (
									user?.inGame === null ||
									user?._id === currentUserData?._id ? (
										<b
											className="yellow"
											style={{
												textShadow: "0 0 1px lightgrey",
												filter: "drop-shadow(0 0 1px lightgrey)",
											}}>
											NOW
										</b>
									) : (
										<b
											className="yellow"
											style={{
												textShadow: "0 0 1px lightgrey",
												filter: "drop-shadow(0 0 1px lightgrey)",
											}}>
											{
												props?.gameProperties?.find(
													(game) =>
														game?.acronym ===
														user?.inGame
												)?.name || "Unknown" // if none is found
											}
										</b>
									)
								) : (
									<b
										style={{
											textShadow: "0 0 1px lightgrey",
											filter: "drop-shadow(0 0 1px lightgrey)",
										}}>
										{moment(user?.lastOnline).format(
											"MMM D, YYYY"
										)}
									</b>
								)}
							</span>
						</>
					)}
				</div>
			</div>
			{userRatesModal()}
			{userCommentsModal()}
		</div>
	);
};

const MappedRates = ({
	index,
	level,
	canSeeLevels,
	currentUserData,
	userID,
	setUser,
	setRatedLevelsChanged,
}) => {
	const [deleteState, setDeleteState] = useState(false);
	const [deleted, setDeleted] = useState(false);

	const history = useHistory();

	const levelID = level?._id;
	const rate = level?.rate;

	const handleClick = (e) => {
		e.preventDefault();
		// return error if rate is deleted.
		if (deleted) return toast.error("This rate has already been deleted!");

		if (!deleteState) {
			// set delete state to true
			setDeleteState(true);
			// reset after 3 seconds
			setTimeout(() => setDeleteState(false), 3000);
			return toast.warn("Click again to confirm permanent removal.");
		} else {
			// get index of rate for user
			const rateIndex = level?.rateIndex;

			// perform the backend call if delete state is true
			removeLevelRate(levelID, { user: userID, index: rateIndex })
				.then((response) => {
					// update the state of the user
					const data = response?.data;
					setDeleteState(false);
					setUser((prevState) => {
						return {
							...prevState,
							rates: data.userRates,
						};
					});

					// alert of deletion
					justDeletedRate = true;
					setRatedLevelsChanged(true);
				})
				.catch((error) => {
					setDeleteState(false);
					if (error?.response?.status === 403) setDeleted(true);
				});
		}
	};

	return (
		<>
			{window.innerWidth > 334 && (
				<div className={canSeeLevels ? "col-1" : "col-3"}>
					<div
						className="vote-logs-text"
						style={{
							color: deleteState || deleted ? "red" : "",
							transition: "color 0.3s ease-in-out",
							whiteSpace: "nowrap",
						}}>
						#{index + 1}
					</div>
				</div>
			)}
			<div className={canSeeLevels ? "col-5" : "col-9"}>
				<MapStars
					level={null}
					rate={rate}
					width="10mm"
					height="16mm"
					top={undefined}
					color={
						deleteState || deleted
							? "#c70700"
							: rate === 5
							? "#2cd40f"
							: ""
					}
				/>
			</div>
			{canSeeLevels ? (
				<>
					<div className="col-5">
						<a
							href={`/levels/${level?._id}`}
							style={{
								color: deleteState || deleted ? "red" : "",
								transition: "color 0.3s ease-in-out",
							}}
							onClick={(e) => {
								e.preventDefault();
								history.push(`/levels/${level?._id}`);
							}}
							className="vote-logs-text">
							{level?.name}
						</a>
					</div>
					{currentUserData &&
						(currentUserData?._id === userID ||
							currentUserData?.isStaff) && (
							<div className="col-1">
								<div className="vote-logs-text">
									<OverlayTrigger
										overlay={
											<Tooltip id="removeRate">
												{deleted
													? "This rate has already been deleted!"
													: deleteState
													? "Confirm removal"
													: "Remove star rate"}
											</Tooltip>
										}>
										<a
											href="#!"
											className="material-icons thread-icon"
											style={{
												color:
													deleteState || deleted
														? "red"
														: "",
												transition:
													"color 0.3s ease-in-out",
											}}
											onClick={(e) => handleClick(e)}>
											delete
										</a>
									</OverlayTrigger>
								</div>
							</div>
						)}
				</>
			) : null}
			<hr
				style={{
					marginBottom: "0px",
					marginTop: "4px",
				}}
			/>
		</>
	);
};

const MappedComments = ({ comment }) => {
	const history = useHistory();
	const href = `/levels/${comment?.level?._id}`;
	const refHref = href + `?referer=${comment?._id}`;
	const isPublic = comment?.level?.isPublic === true;

	// return html
	return (
		<div
			style={{
				textAlign: "left",
				wordBreak: "break-word",
			}}>
			<h4>
				{isPublic ? (
					<>
						<a
							href={href}
							onClick={(e) => {
								e.preventDefault();
								history.push(href);
							}}>
							{comment?.level?.name}
						</a>
						&nbsp;|&nbsp;
						<a
							href={refHref}
							className="wildheart-green"
							onClick={(e) => {
								e.preventDefault();
								history.push(refHref);
							}}>
							View comment
						</a>
					</>
				) : (
					<i>Hidden level</i>
				)}
			</h4>
			<div
				style={{
					whiteSpace: "pre-wrap",
					padding: "8px",
					fontSize: "15px",
					border: "1px solid white",
					borderRadius: "16px",
					position: "relative",
					backgroundColor: "rgba(180, 53, 254, 0.2)",
					paddingBottom: "32px",
				}}
				className="hover-colour">
				{isPublic ? (
					<ReadMore
						content={comment?.content}
						document={comment}
						height={250}
					/>
				) : (
					<i>The level this comment belongs to isn't public</i>
				)}
				<span className="comment-footer__date">
					Posted on{" "}
					<b>
						{moment(comment?.postDate).format(
							`MMM D, YYYY - h:mm A`
						)}
					</b>
				</span>
			</div>
		</div>
	);
};

export default StatsOverlay;
