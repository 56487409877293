import React, { useCallback, useEffect, useState } from "react";
import BBCode from "@bbob/react/es/Component";
import BBCodeParser from "../../../functions/applyBBCode";
import Linkify from "react-linkify";
import MapStars from "../../Insertions/MapStars";
import { useHistory } from "react-router-dom";

// constants
import { FEATURED_LEVEL } from "../../../constants";

const MainProfile = (props) => {
	const { latestLevel } = props?.mainProfileData;
	const [profileCards, setProfileCards] = useState(
		props?.user?.profile?.length ? props?.user?.profile : [1, 2]
	);
	const [hasValidatedCards, setHasValidatedCards] = useState(false);
	const userData = props.user;
	// filter out any cards that dont exist
	const validateCards = useCallback(() => {
		// validate whether each entry is in here
		setProfileCards((prevState) =>
			prevState?.filter((content) => {
				if (![1, 2].includes(content)) return false;
				if (content === 1 && !userData?._id) return false;
				if (content === 2 && !latestLevel) return false;
				return true;
			})
		);
		return setHasValidatedCards(true);
	}, [latestLevel, userData?._id]);
	// correct the cards
	useEffect(() => {
		validateCards();

		return () => {
			setHasValidatedCards(false);
			setProfileCards(
				userData?.profile?.length ? userData?.profile : [1, 2]
			);
		};
	}, [validateCards, hasValidatedCards, userData?.profile]);

	// return a card by its value
	const getContentForCard = (card) => {
		switch (card) {
			case 1:
				return <AboutMe userData={userData} />;
			case 2:
				return (
					<LatestLevel
						latestLevel={latestLevel}
						username={userData?.username}
					/>
				);
		}
	};

	// maps all cards in the order of the array
	return hasValidatedCards ? (
		<>
			{profileCards?.map((card, index) => {
				const isFinalEntry =
					index + 1 === profileCards?.length &&
					profileCards?.length % 2 !== 0;
				return (
					<div
						key={index}
						className={`profile__middle__section card ${
							isFinalEntry ? "full-width" : ""
						}`}>
						{getContentForCard(card)}
					</div>
				);
			})}
		</>
	) : null;
};

const AboutMe = ({ userData }) => {
	const about = userData?.about;

	return (
		<div className="profile__middle__section__about">
			<div style={{ overflowY: "auto" }}>
				<h3>About me</h3>
				<hr />
				<Linkify
					componentDecorator={(decoratedHref, decoratedText, key) => (
						<a
							href={decoratedHref}
							target="_blank" // open links in a new tab
							rel="noopener noreferrer"
							key={key}>
							{decoratedText}
						</a>
					)}>
					<BBCode
						plugins={[BBCodeParser()]}
						options={{
							onlyAllowTags: [
								"i",
								"b",
								"u",
								"s",
								"color",
								"colour",
								"spoiler",
							],
						}}>
						{about ||
							"[i]This user isn't very about themselves.[/i]"}
					</BBCode>
				</Linkify>
			</div>
		</div>
	);
};

const LatestLevel = ({ latestLevel, username }) => {
	const history = useHistory();
	const [opacity, setOpacity] = useState<string>("1"); // Initial opacity value

	return (
		<div
			className="profile__middle__section__latestlevel"
			title={`Play ${username}'s latest level!`}
			onMouseEnter={() => latestLevel?.thumbnail && setOpacity("0")}
			onMouseLeave={() => setOpacity("1")}
			style={{
				backgroundImage: latestLevel?.thumbnail
					? `url(${latestLevel?.thumbnail})`
					: "linear-gradient(0deg, var(--btn-normal-before-primary), var(--btn-normal-before-secondary))",
			}}
			onClick={() => {
				history.push(`/levels/${latestLevel?._id}`);
				window.scrollTo(0, 0);
			}}
			onError={(e: any) => {
				e.target.style.backgroundImage = `linear-gradient(
					0deg,
					var(--btn-normal-before-primary),
					var(--btn-normal-before-secondary)
				);`;
			}}>
			<div className="profile__middle__section__latestlevel__info">
				<div
					className="text-card"
					style={{
						opacity: opacity,
						transition: "opacity 0.7s ease",
						...(latestLevel?.status === FEATURED_LEVEL
							? {
									backgroundColor: "rgba(0, 94, 255, 0.8)",
									boxShadow: "none",
									border: "2px solid white",
							  }
							: {}),
					}}>
					<h3
						className={
							latestLevel?.status === FEATURED_LEVEL
								? "featured-glow"
								: ""
						}>
						&nbsp;{latestLevel?.name}&nbsp;
					</h3>
					<span style={{ position: "relative", top: "-8px" }}>
						<MapStars
							level={latestLevel}
							rate={null}
							top=""
							color={
								latestLevel?.rating >= 4.9 &&
								latestLevel?.rates?.length > 5
									? "#2cd40f"
									: ""
							}
							width={"15mm"}
							height={"22mm"}
						/>
					</span>
				</div>
			</div>
		</div>
	);
};

export default MainProfile;
